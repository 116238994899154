export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video'
}

export interface Banner {
  id: string;
  name: string;
  fileType: FileType;
  fileUrl: string;
  redirectUrl: string;
  index: number;
}
