import React, { useState } from 'react';
import { toast } from 'react-toastify';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import {
  addDoc,
  arrayRemove,
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  writeBatch
} from '@firebase/firestore';
import { Label } from '@models/label';
import { useDiscounts, useLabels } from '@queries';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@ui/Button';
import { EdittableAndSelectableItems } from '@ui/EdittableAndSelectableItems';
import { Input } from '@ui/Input';

import { db } from '../../firebase/firebaseConfig';

export const LabelsContainer = () => {
  const [newLabelName, setNewLabelName] = useState<string>('');
  const [newLabelIndex, setNewLabelIndex] = useState<number>(0);
  const [labelIdToEdit, setLabelIdToEdit] = useState<string | null>(null);
  const [isDeletingLabel, setIsDeletingLabel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: labels, isPending: isFetchingLabels } = useLabels();
  const { data: discounts } = useDiscounts();

  const queryClient = useQueryClient();

  const invalidateLabelsQuery = () =>
    queryClient.invalidateQueries({ queryKey: ['labels'] });

  const replaceLabelsInFirebase = async (
    currentLabels: Label[],
    newLabels: Label[]
  ) => {
    setIsLoading(true);

    // Delete all current labels and add the rearranged ones
    // Not the most efficient way to do this, but it's the simplest
    try {
      for await (const label of currentLabels) {
        await deleteDoc(doc(db, 'labels', label.id));
      }
      for await (const label of newLabels) {
        // Keep the same ID if label already exists
        if (label.id) {
          await setDoc(doc(db, 'labels', label.id), label);
        } else {
          await addDoc(collection(db, 'labels'), label);
        }
      }

      toast.success('Labels updated successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setIsLoading(false);
      return toast.error(`💥 ${e.message}`);
    } finally {
      invalidateLabelsQuery();
      setIsLoading(false);
    }
  };

  const handleAddNewLabel = async () => {
    if (newLabelName === '') {
      return;
    }

    if (labels.some((label) => label.name === newLabelName)) {
      toast.error('Label with that name already exists');
      return;
    }

    const newLabel = {
      name: newLabelName,
      index: Math.min(Math.max(0, newLabelIndex), labels.length)
    };

    const sortedLabels = [...labels].sort((a, b) => a.index - b.index);

    const insertionIndex = sortedLabels.findIndex(
      (label) => label.index >= newLabel.index
    );

    if (insertionIndex === -1) {
      sortedLabels.push(newLabel as Label);
    } else {
      sortedLabels.splice(insertionIndex, 0, newLabel as Label);
    }

    const updatedLabels = sortedLabels.map((label, index) => ({
      ...label,
      index
    }));

    await replaceLabelsInFirebase(labels, updatedLabels);
    resetForm();
  };

  const handleEditExistingLabel = async () => {
    const labelToEdit = labels.find((label) => label.id === labelIdToEdit);

    if (!labelToEdit) {
      resetForm();
      return toast.error(
        `Cannot find existing label with ID: ${labelIdToEdit}`
      );
    }

    if (
      newLabelName === labelToEdit.name &&
      newLabelIndex === labelToEdit.index
    ) {
      resetForm();
      return toast.info(
        `No changes made to exising label: ${labelToEdit.name}`
      );
    }

    const oldIndex = labelToEdit.index;
    const newIndex = Math.min(Math.max(0, newLabelIndex), labels.length - 1);

    const newLabels = labels.map((label) => {
      if (label.id === labelIdToEdit) {
        return { ...label, name: newLabelName, index: newIndex };
      }

      if (oldIndex < newIndex) {
        if (label.index > oldIndex && label.index <= newIndex) {
          return { ...label, index: label.index - 1 };
        }
      } else if (oldIndex > newIndex) {
        if (label.index >= newIndex && label.index < oldIndex) {
          return { ...label, index: label.index + 1 };
        }
      }

      return label;
    });

    newLabels.sort((a, b) => a.index - b.index);

    await replaceLabelsInFirebase(labels, newLabels);
    resetForm();
  };

  const handleDeleteLabel = async (labelId: string) => {
    setIsDeletingLabel(true);

    try {
      const discountsWithLabel = discounts.filter((discount) =>
        discount.labelIds.includes(labelId)
      );

      const labelToDelete = labels.find((label) => label.id === labelId);
      if (!labelToDelete) {
        throw new Error('Label not found');
      }

      const deletedIndex = labelToDelete.index;

      await deleteDoc(doc(db, 'labels', labelId));

      // Remove label from discounts that have it assigned
      for (const discount of discountsWithLabel) {
        if (discount.labelIds.includes(labelId)) {
          await updateDoc(doc(db, 'discounts', discount.id), {
            labelIds: arrayRemove(labelId)
          });
        }
      }

      const updatedLabels = labels
        .filter((label) => label.id !== labelId)
        .map((label) => {
          if (label.index > deletedIndex) {
            return { ...label, index: label.index - 1 };
          }
          return label;
        });

      const batch = writeBatch(db);
      updatedLabels.forEach((label) => {
        const labelRef = doc(db, 'labels', label.id);
        batch.update(labelRef, { index: label.index });
      });
      await batch.commit();

      toast.success('Label deleted successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setIsDeletingLabel(false);
      return toast.error(`💥 ${e.message}`);
    } finally {
      invalidateLabelsQuery();
      setIsDeletingLabel(false);
      resetForm();
    }
  };

  const handleStartEditingLabel = (label: Label) => {
    setLabelIdToEdit(label.id);
    setNewLabelName(label.name);
    setNewLabelIndex(label.index);
  };

  const resetForm = () => {
    setLabelIdToEdit(null);
    setNewLabelName('');
    setNewLabelIndex(0);
    setIsLoading(false);
  };

  const handleActionButtonFunction = !labelIdToEdit
    ? handleAddNewLabel
    : handleEditExistingLabel;

  const actionButtonLabel = labelIdToEdit ? 'Edit label' : 'Add label';

  return (
    <Wrapper>
      <Text>Labels</Text>
      <EdittableAndSelectableItems
        items={labels}
        isFetchingItems={isFetchingLabels}
        // @ts-ignore
        handleStartEditingItem={handleStartEditingLabel}
      />
      <Text>{actionButtonLabel}</Text>
      <InputContainer>
        <NewLabelNameInput>
          <Input
            value={newLabelName}
            placeholder={'Label name...'}
            type={'text'}
            onChange={(e) => setNewLabelName(e.target.value)}
          />
        </NewLabelNameInput>
        <NewLabelIndexInput>
          <Input
            value={newLabelIndex}
            placeholder={'Index...'}
            onChange={(e) => {
              if (!e.target.value.match(/^[0-9]*$/)) {
                return;
              }
              setNewLabelIndex(Number(e.target.value));
            }}
          />
        </NewLabelIndexInput>
      </InputContainer>
      <ButtonContainer>
        <Button
          label={actionButtonLabel}
          disabled={newLabelName === ''}
          loading={isLoading}
          onClick={handleActionButtonFunction}
        />
        {labelIdToEdit && (
          <Button
            label={'Delete label'}
            backgroundColor={Color.RED}
            loading={isDeletingLabel}
            onClick={() => handleDeleteLabel(labelIdToEdit)}
          />
        )}
        {labelIdToEdit && <Button label={'Stop editing'} onClick={resetForm} />}
      </ButtonContainer>
    </Wrapper>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
`;

const Text = styled.p`
  font-size: 24px;
  color: ${Color.WHITE};
`;

const InputContainer = styled.div`
  display: flex;
  gap: 5px;
  margin: 10px 0 10px 0;
`;

const NewLabelNameInput = styled.div`
  flex: 3;
`;

const NewLabelIndexInput = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;
