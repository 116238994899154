import {
  ImageDetails,
  ImageInfo,
  TShirtColor
} from '@containers/adminPanel/utils';
import { InfiniteData } from '@tanstack/react-query';

export enum TShirtType {
  MEN = 'men',
  WOMEN = 'women',
  KIDS = 'kids',
  OVERSIZED = 'oversized'
}

export interface TShirtSizes {
  men: TShirtSize[];
  women: TShirtSize[];
  kids: TShirtSize[];
  oversized: TShirtSize[];
}

export enum TShirtSize {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XLPlus = 'XL+',
  XXL = 'XXL',
  XXXL = 'XXXL',
  K98 = 'K98',
  K110 = 'K110',
  K122 = 'K122',
  K132 = 'K132',
  K144 = 'K144',
  K156 = 'K156',
  K168 = 'K168'
}

export interface ImagesMen {
  white: ImageInfo;
  black: ImageInfo;
  red: ImageInfo;
  darkBlue: ImageInfo;
  lightBlue: ImageInfo;
  darkGreen: ImageInfo;
  yellow: ImageInfo;
}

export interface ImagesWomen {
  white: ImageInfo;
  black: ImageInfo;
  lightPink: ImageInfo;
}

export interface ImagesKids {
  white: ImageInfo;
  black: ImageInfo;
  red: ImageInfo;
  blue: ImageInfo;
  yellow: ImageInfo;
  lightPink: ImageInfo;
}

export interface ImagesOversized {
  white: ImageInfo;
  black: ImageInfo;
}

export type TShirtImages = ImageDetails;

interface Thumbnail {
  name: string;
  url: string;
}

export interface TShirtThumbnail extends Thumbnail {
  color: TShirtColor;
  type: TShirtType;
}

export enum ProductType {
  TSHIRT = 'TSHIRT',
  MUG = 'MUG'
}

export interface TShirt {
  id: string;
  createdAt: string;
  createdAtTimestamp: number;
  title: string;
  description: string;
  thumbnail: TShirtThumbnail;
  images: TShirtImages;
  price: number;
  sizes: TShirtSizes;
  labels: string[];
  type: ProductType.TSHIRT;
  views: number;
  mock: boolean;
}

export interface Mug {
  id: string;
  createdAt: string;
  createdAtTimestamp: number;
  title: string;
  description: string;
  thumbnail: Thumbnail;
  image: ImageInfo;
  price: number;
  labels: string[];
  type: ProductType.MUG;
  views: number;
  mock: boolean;
}

export type Product = TShirt | Mug;

export interface ProductPage {
  products: Product[];
  currentPage: number;
  nextPage: number | null;
}

export type ProductsData = InfiniteData<ProductPage, unknown> | undefined;
