import React from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { icons } from '@assets/icons';
import { Map } from '@features/map/Map';
import { useCustomNavigate } from '@hooks';
import { Button } from '@ui/Button';
import { Divider } from '@ui/Divider';

const dividerStyles = `
  width: 90%; 
  justify-self: center;
  margin: 1rem 0;
`;

export const ContactPage = () => {
  const navigate = useCustomNavigate();

  const navigateToFeedback = () => navigate('/feedback');

  return (
    <Wrapper>
      <Row>
        <Title>Контакти</Title>
        <icons.FaPhone size={30} color={Color.DARK_GRAY} />
      </Row>
      <Map />
      <InfoWrapper>
        <InfoRow>
          <Text>Адрес:</Text>
          <BoldText>
            гр. Варна, бул. Сливница 165, ет. 0, срещу Costa кафе
          </BoldText>
        </InfoRow>

        <Divider additionalStyles={dividerStyles} />

        <InfoRow>
          <Text>Имейл:</Text>
          <Email href={'mailto:teniski.varna2022@gmail.com'}>
            teniski.varna2022@gmail.com
          </Email>
        </InfoRow>

        <Divider additionalStyles={dividerStyles} />

        <InfoRow>
          <Text>Телефон:</Text>
          <Tel href={'tel:0888228975'}>088 822 8975</Tel>
        </InfoRow>

        <Divider additionalStyles={dividerStyles} />

        <InfoRow>
          <Text>Работно време за доставки:</Text>
          <BoldText>10:00 - 17:00</BoldText>
        </InfoRow>

        <Divider additionalStyles={dividerStyles} />

        <InfoRow>
          <Text>Работно време на физически магазин в Делта Планет Мол:</Text>
          <BoldText>10:00 - 21:00</BoldText>
        </InfoRow>
      </InfoWrapper>

      <InfoWrapper>
        <InfoText>Информация за фирмата:</InfoText>
        <Text>
          Тениски Варна ЕООД
          <br />
          BG207170625
          <br />
          гр. Варна бул. Сливница 185 етаж 0
          <br />
          Томислав Димитров
        </Text>
      </InfoWrapper>

      <FeedbackWrapper>
        <Text>Имате идея какво можем да подобрим?</Text>
        <Button
          label={'Оставете обратна връзка'}
          onClick={navigateToFeedback}
        />
      </FeedbackWrapper>
    </Wrapper>
  );
};

const FeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 2rem;
`;

const InfoText = styled.p`
  font-weight: bold;
  text-align: left;
`;

const Tel = styled.a`
  font-weight: bold;
  text-align: right;
  color: ${Color.DARK_GRAY};
`;

const Email = styled.a`
  font-weight: bold;
  text-align: right;
  color: ${Color.DARK_GRAY};
`;

const BoldText = styled.p`
  font-weight: bold;
  text-align: right;
`;

const Text = styled.p``;

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 10px;
  width: 100%;
`;

const InfoWrapper = styled.div`
  margin-top: 2rem;
  hyphens: none;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${Color.DARK_GRAY};
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1.5rem;
  width: 100%;
`;

const Wrapper = styled.div`
  padding-bottom: 1.5rem;
  color: ${Color.DARK_GRAY};
`;
