import { algoliasearch, Hit } from 'algoliasearch';

import { useScreenSize } from '@hooks';
import { Product, ProductPage, ProductType } from '@models/product';
import { ScreenSize } from '@models/screen';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { isDev } from '@utils/environment';

const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID ?? '',
  searchKey: process.env.REACT_APP_ALGOLIA_SEARCH_KEY ?? ''
};

const client = algoliasearch(algolia.appId, algolia.searchKey);

const INDEX_NAME = 'teniski';

const mapHitsToProducts = (hits: Hit[]): Product[] =>
  // eslint-disable-next-line
  hits.map((hit: any) => {
    if (hit.type === ProductType.TSHIRT) {
      return {
        id: hit.objectID,
        createdAt: hit.createdAt,
        createdAtTimestamp: hit.createdAtTimestamp,
        title: hit.title,
        description: hit.description,
        thumbnail: hit.thumbnail,
        images: hit.images,
        price: hit.price,
        sizes: hit.sizes,
        labels: hit.labels,
        type: ProductType.TSHIRT,
        views: hit.views,
        mock: hit.mock
      };
    }

    return {
      id: hit.objectID,
      createdAt: hit.createdAt,
      createdAtTimestamp: hit.createdAtTimestamp,
      title: hit.title,
      description: hit.description,
      thumbnail: hit.thumbnail,
      image: hit.image,
      price: hit.price,
      labels: hit.labels,
      type: ProductType.MUG,
      views: hit.views,
      mock: hit.mock
    };
  });

interface Props {
  searchTerm: string;
  labelId?: string;
  paginated?: boolean;
}

export const useProducts = ({ searchTerm, labelId, paginated }: Props) => {
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize === ScreenSize.SMALL;

  const hitsPerPage = paginated ? (isSmallScreen ? 8 : 9) : 1000;

  const getFilters = () => {
    let filters = !isDev() ? 'mock:false' : '';

    if (labelId) {
      filters = filters + `${!isDev() ? ' AND ' : ''}labels:${labelId}`;
    }

    if (!labelId && isDev()) {
      return undefined;
    }

    return filters;
  };

  const getProductsFromAlgolia = async ({
    pageParam
  }: {
    pageParam: number;
  }): Promise<ProductPage> => {
    const queryResponse = await client.searchSingleIndex({
      indexName: INDEX_NAME,
      searchParams: {
        query: searchTerm,
        hitsPerPage,
        page: pageParam,
        filters: getFilters()
      }
    });

    const products = mapHitsToProducts(queryResponse.hits);

    const hasNextPage =
      queryResponse.nbPages && pageParam < queryResponse.nbPages;

    return {
      products,
      currentPage: pageParam,
      nextPage: hasNextPage ? pageParam + 1 : null
    };
  };

  return useInfiniteQuery({
    queryKey: ['products', { searchTerm, labelId }],
    queryFn: getProductsFromAlgolia,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    placeholderData: keepPreviousData
  });
};
