import React from 'react';

import { TShirtColor } from '@containers/adminPanel/utils';
import { DetailsContainerDesktop } from '@containers/Details/DetailsContainerDesktop';
import { DetailsContainerMobile } from '@containers/Details/DetailsContainerMobile';
import { useScreenSize } from '@hooks';
import { AppConfig } from '@models/appConfig';
import { Product, TShirtSize, TShirtType } from '@models/product';
import { ScreenSize } from '@models/screen';

import { TShirtSliderImage } from './utils';

export interface Props {
  product: Product;
  images: TShirtSliderImage[];
  tShirtTypes: TShirtType[];
  discountedPrice: number | null;
  selectedType: TShirtType | null;
  onSelectType: (type: TShirtType) => void;
  selectedSize: TShirtSize | null;
  onSelectSize: (size: TShirtSize | null) => void;
  selectedColor: TShirtColor | null;
  onSelectColor: (color: TShirtColor, isMobile?: boolean) => void;
  customizationNotes: string;
  onCustomizationNotesChange: (notes: string) => void;
  imageHasLoaded: boolean;
  onImageLoad: () => void;
  onGoBack: () => void;
  onShowSizeInfo: () => void;
  showSizeInfo: boolean;
  onShowShippingInfo: () => void;
  showShippingInfo: boolean;
  onShowMaterialsInfo: () => void;
  showMaterialsInfo: boolean;
  onToggleCart: () => void;
  showCart: boolean;
  appConfig: AppConfig;
}

export const DetailsContainer = ({ ...props }: Props) => {
  const screenSize = useScreenSize();

  return screenSize !== ScreenSize.SMALL ? (
    <DetailsContainerDesktop {...props} />
  ) : (
    <DetailsContainerMobile {...props} />
  );
};
