import React from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { MenuLinks } from '@features/menu/MenuLinks';
import { MenuLogo } from '@features/menu/MenuLogo';
import { useCustomNavigate } from '@hooks';

export const MenuDesktop = () => {
  const navigate = useCustomNavigate();
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <div>
      <MenuContainer>
        <MenuLogo onClick={() => navigate('/')} />
        <MenuLinks currentPage={currentPage} />
      </MenuContainer>
    </div>
  );
};

const MenuContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  padding-bottom: 20px;
`;
