import { Label } from '@models/label';
import { createSlice } from '@reduxjs/toolkit';

export type LabelsInitialState = {
  selectedLabel: Label | undefined;
};

const initialState: LabelsInitialState = {
  selectedLabel: undefined
};

export const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    setSelectedLabel: (state, { payload }) => {
      state.selectedLabel = payload;
    }
  }
});

export const labelsActions = labelsSlice.actions;
