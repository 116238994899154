import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { doc, updateDoc } from 'firebase/firestore';
import styled from 'styled-components';

import { Color } from '@assets/constants';
import { useAppSelector } from '@hooks';
import { ActivityIndicator } from '@ui/ActivityIndicator';
import Switch from '@ui/Switch';

import { db } from '../../firebase/firebaseConfig';

export const AppConfigContainer = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean | null>(
    null
  );
  const [isTransactionsTestMode, setIsTransactionsTestMode] = useState<
    boolean | null
  >(null);
  const [areTransactionsDisabled, setAreTransactionsDisabled] = useState<
    boolean | null
  >(null);
  const [isUpdatingMaintenanceMode, setIsUpdatingMaintenanceMode] =
    useState<boolean>(false);
  const [isUpdatingTransactionsTestMode, setIsUpdatingTransactionsTestMode] =
    useState<boolean>(false);
  const [isUpdatingTransactionsDisabled, setIsUpdatingTransactionsDisabled] =
    useState<boolean>(false);

  const appConfig = useAppSelector((state) => state.appConfig);

  const toggleMaintenanceMode = useCallback(async (isOn: boolean) => {
    setIsUpdatingMaintenanceMode(true);

    try {
      await updateDoc(doc(db, 'config', 'maintenance'), { isOn });
      setIsMaintenanceMode(isOn);
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    } finally {
      setIsUpdatingMaintenanceMode(false);
    }
  }, []);

  const toggleTransactionsTestMode = useCallback(async (testMode: boolean) => {
    setIsUpdatingTransactionsTestMode(true);

    try {
      await updateDoc(doc(db, 'config', 'transactions'), { testMode });
      setIsTransactionsTestMode(testMode);
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    } finally {
      setIsUpdatingTransactionsTestMode(false);
    }
  }, []);

  const toggleTransactionsDisabled = async (disabled: boolean) => {
    setIsUpdatingTransactionsDisabled(true);

    try {
      await updateDoc(doc(db, 'config', 'transactions'), { disabled });
      setAreTransactionsDisabled(disabled);
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    } finally {
      setIsUpdatingTransactionsDisabled(false);
    }
  };

  useEffect(() => {
    setIsMaintenanceMode(appConfig.isInMaintenanceMode);
    setIsTransactionsTestMode(appConfig.transactions.areInTestMode);
    setAreTransactionsDisabled(appConfig.transactions.areDisabled);
  }, [appConfig.isInMaintenanceMode, appConfig.transactions]);

  const getStatusText = (status: boolean | null) => (status ? 'ON' : 'OFF');

  return (
    <Wrapper>
      <Column>
        <Row>
          <>
            <Text>Maintenance mode</Text>
            {isUpdatingMaintenanceMode ? (
              <ActivityIndicator size={25} color={Color.ACCENT} />
            ) : (
              <BoldText>{getStatusText(isMaintenanceMode)}</BoldText>
            )}
          </>
        </Row>
        <Row>
          <>
            <Text>Transactions test mode</Text>
            {isUpdatingTransactionsTestMode ? (
              <ActivityIndicator size={25} color={Color.ACCENT} />
            ) : (
              <BoldText>{getStatusText(isTransactionsTestMode)}</BoldText>
            )}
          </>
        </Row>
        <Row>
          <>
            <Text>Transactions disabled</Text>
            {isUpdatingTransactionsDisabled ? (
              <ActivityIndicator size={25} color={Color.ACCENT} />
            ) : (
              <BoldText>{getStatusText(areTransactionsDisabled)}</BoldText>
            )}
          </>
        </Row>
      </Column>

      <Column>
        <Row>
          {isMaintenanceMode !== null ? (
            <Switch
              id={'maintenance-mode'}
              isOn={isMaintenanceMode}
              handleToggle={() => toggleMaintenanceMode(!isMaintenanceMode)}
            />
          ) : (
            <ActivityIndicator size={100} color={Color.ACCENT} />
          )}
        </Row>
        <Row>
          {isTransactionsTestMode !== null ? (
            <Switch
              id={'transactions-test-mode'}
              isOn={isTransactionsTestMode}
              handleToggle={() =>
                toggleTransactionsTestMode(!isTransactionsTestMode)
              }
            />
          ) : (
            <ActivityIndicator size={100} color={Color.ACCENT} />
          )}
        </Row>
        <Row>
          {areTransactionsDisabled !== null ? (
            <Switch
              id={'transactions-disabled'}
              isOn={areTransactionsDisabled}
              handleToggle={() =>
                toggleTransactionsDisabled(!areTransactionsDisabled)
              }
            />
          ) : (
            <ActivityIndicator size={100} color={Color.ACCENT} />
          )}
        </Row>
      </Column>
    </Wrapper>
  );
};

const BoldText = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${Color.WHITE};
`;

const Text = styled.p`
  font-size: 16px;
  color: ${Color.WHITE};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 70px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;
  max-width: 400px;
  align-self: center;
`;
