import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { getDiscountForProduct } from '@containers/adminPanel/utils';
import { ProductCard } from '@features/home/ProductCard';
import { Label } from '@models/label';
import { ProductsData } from '@models/product';
import { useActiveDiscounts } from '@queries';
import { ActivityIndicator } from '@ui/ActivityIndicator';

interface Props {
  productsData: ProductsData;
  cols?: number;
  onLoadMore?: () => void;
  hasNextPage?: boolean;
  selectedLabel?: Label;
  onSelectProductToEdit?: (productId: string) => void;
}

export const ProductList = ({
  productsData,
  cols,
  onLoadMore,
  hasNextPage,
  selectedLabel,
  onSelectProductToEdit
}: Props) => {
  const { data: activeDiscounts } = useActiveDiscounts();

  if (!productsData || !activeDiscounts) {
    return null;
  }

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={onLoadMore ? () => onLoadMore() : () => ({})}
        hasMore={hasNextPage}
        loader={
          <Loader key={'loader'}>
            <Text>Зареждане на още продукти...</Text>
            <ActivityIndicator size={75} color={Color.ACCENT} />
          </Loader>
        }
        threshold={0}
      >
        {productsData.pages.map((page) => (
          <Page cols={cols} key={page.currentPage}>
            {page.products.map((product) => {
              if (selectedLabel && !product.labels.includes(selectedLabel.id)) {
                return null;
              }

              const discount = getDiscountForProduct(product, activeDiscounts);

              return (
                <ProductCard
                  product={product}
                  discount={discount}
                  onSelectProductToEdit={onSelectProductToEdit}
                  key={product.id}
                />
              );
            })}
          </Page>
        ))}
      </InfiniteScroll>
    </>
  );
};

const Text = styled.p`
  font-size: 1rem;
  color: ${Color.GRAY};
  text-align: center;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  padding: 1rem;
`;

const Page = styled.div<{ cols?: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8rem;
  margin-bottom: 0.8rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(${({ cols }) => cols ?? 3}, 1fr);
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }
`;
