import { MailGunResponse } from '@models/mailgun';
import { useMutation } from '@tanstack/react-query';
import { EmailProps } from '@utils/emailUtils';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

export const useAdminEmail = () => {
  const sendEmailToAdmin = async (
    props: EmailProps
  ): Promise<MailGunResponse> => {
    const url = `${REACT_APP_TENISKI_API_BASE_URL}/mailgun/sendEmailToAdmin`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(props)
      });
      const data = await response.json();

      return data;
    } catch (e: unknown) {
      console.log('error', (e as Error).message);
      return { status: 500, id: '', message: (e as Error).message };
    }
  };

  return useMutation({
    mutationFn: (props: EmailProps) => sendEmailToAdmin(props)
  });
};
