import React, { useEffect } from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { ProductListContainer } from '@containers/HomeContainer';
import { ProductList } from '@features/home/ProductList';
import { useAppSelector, useFavorites } from '@hooks';
import { EmptyProductList } from '@ui/EmptyList';

export const FavoritesPage = () => {
  const favorites = useAppSelector((state) => state.favorites);

  const { checkFavorites } = useFavorites();

  useEffect(() => {
    checkFavorites();
  }, []);

  const productsData = {
    pageParams: [],
    pages: [
      {
        products: favorites,
        currentPage: 1,
        nextPage: null
      }
    ]
  };

  return (
    <>
      <Row>
        <Title>Любими</Title>
        <RedHeartIcon src={require('../assets/images/red-heart-icon.png')} />
      </Row>
      <ProductListContainer>
        {favorites.length === 0 ? (
          <EmptyProductList />
        ) : (
          <ProductList productsData={productsData} />
        )}
      </ProductListContainer>
    </>
  );
};

const RedHeartIcon = styled.img`
  width: 30px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${Color.DARK_GRAY};
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1.5rem;
  width: 100%;
`;
