import { doc, getDoc, updateDoc } from 'firebase/firestore';

import { Product } from '@models/product';
import { useMutation } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';
import { useIndexProduct } from './useIndexProduct';

export const useProductViews = (productId: string) => {
  const { mutateAsync: indexProduct } = useIndexProduct();

  const updateProductViews = async () => {
    const docRef = doc(db, 'products', productId);
    const orderDoc = await getDoc(docRef);

    if (!orderDoc.exists()) {
      console.error('Product does not exist!');
      return;
    }

    const currentViews = orderDoc.data().views ?? null;
    const updatedViews = currentViews ? currentViews + 1 : 1;

    await updateDoc(docRef, {
      views: updatedViews
    });

    const updatedProduct = {
      ...(orderDoc.data() as Product),
      views: updatedViews as number
    };

    await indexProduct(updatedProduct);
  };

  return useMutation({
    mutationFn: updateProductViews
  });
};
