import { mapSizeToString } from '@containers/adminPanel/utils';
import { getImageAltText } from '@containers/Details/utils';
import {
  translateColorToBulgarian,
  translateTypeToBulgarian
} from '@features/cart/utils';
import { MailGunResponse } from '@models/mailgun';
import { MyPosSuccessData, OrderShippingInfo } from '@models/order';
import { ProductType } from '@models/product';
import { PromoCode } from '@models/promoCode';
import { ShippingData } from '@models/shipping';

import { CartProduct } from '../domain/mappers/cartProductMapper';

export enum OrderEmailType {
  ADMIN_ORDER = 'ADMIN_ORDER',
  CUSTOMER_ORDER_CREATED = 'CUSTOMER_ORDER_CREATED',
  CUSTOMER_ORDER_SENT = 'CUSTOMER_ORDER_SENT'
}

export interface EmailProps {
  to?: string;
  subject: string;
  text: string;
  html: string;
}
export type EmailOrderSuccessProps = EmailProps &
  MyPosSuccessData & { emailType: OrderEmailType };

export const sendOrderEmailToCustomer = async (
  callback: (props: EmailOrderSuccessProps) => Promise<void>,
  myPosSuccessData: MyPosSuccessData,
  orderNumber: string,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData
) => {
  const emailProps: EmailOrderSuccessProps = {
    ...myPosSuccessData,
    to: shippingInfo.email,
    subject: 'Поръчка от Teniski Varna',
    text: 'Благодарим ви, че избрахте нашите продукти!',
    html: generateCustomerOrderHtml(
      OrderEmailType.CUSTOMER_ORDER_CREATED,
      orderNumber,
      shippingInfo,
      items,
      promoCode,
      totalPrice,
      shipping
    ),
    emailType: OrderEmailType.CUSTOMER_ORDER_CREATED
  };

  await callback(emailProps);
};

export const sendOrderEmailToAdmin = async (
  callback: (props: EmailOrderSuccessProps) => Promise<void>,
  myPosSuccessData: MyPosSuccessData,
  orderNumber: string,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData
) => {
  const emailProps: EmailOrderSuccessProps = {
    ...myPosSuccessData,
    subject: `Нова поръчка номер ${orderNumber} на обща стойност ${totalPrice.toFixed(
      2
    )} лв.`,
    text: `Нова поръчка номер ${orderNumber} на обща стойност ${totalPrice.toFixed(
      2
    )} лв.`,
    html: generateAdminOrderEmailHtml(
      orderNumber,
      shippingInfo,
      items,
      promoCode,
      totalPrice,
      shipping
    ),
    emailType: OrderEmailType.ADMIN_ORDER
  };

  await callback(emailProps);
};

export const sendOrderErrorEmailToAdmin = async (
  callback: (props: EmailProps) => Promise<MailGunResponse>,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData,
  error: boolean
) => {
  const emailProps: EmailProps = {
    subject: 'Неуспешна поръчка от Teniski Varna',
    text: 'Неуспешна поръчка',
    html: generateAdminOrderEmailHtml(
      '',
      shippingInfo,
      items,
      promoCode,
      totalPrice,
      shipping,
      error
    )
  };

  await callback(emailProps);
};

export const sendOrderSentEmailToCustomer = async (
  callback: (props: EmailOrderSuccessProps) => Promise<void>,
  myPosSuccessData: MyPosSuccessData,
  orderNumber: string,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData
) => {
  const emailProps: EmailOrderSuccessProps = {
    ...myPosSuccessData,
    subject: 'Вашата поръчка от Teniski Varna е изпратена',
    text: 'Вашата поръчка от Teniski Varna е изпратена',
    to: shippingInfo.email,
    html: generateCustomerOrderHtml(
      OrderEmailType.CUSTOMER_ORDER_SENT,
      orderNumber,
      shippingInfo,
      items,
      promoCode,
      totalPrice,
      shipping
    ),
    emailType: OrderEmailType.CUSTOMER_ORDER_SENT
  };

  await callback(emailProps);
};

export const sendFeedbackEmailToAdmin = async (
  callback: (props: EmailProps) => Promise<MailGunResponse>,
  feedback: string,
  name: string,
  email: string,
  phone: string
) => {
  const emailProps: EmailProps = {
    subject: 'Обратна връзка от Teniski Varna',
    text: 'Нова обратна връзка от клиент',
    html: generateFeedbackHtml(name, email, phone, feedback)
  };
  return await callback(emailProps);
};

const generateAdminOrderEmailHtml = (
  orderNumber: string,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData,
  error?: boolean
) => {
  let productTable = `
    <div style="overflow: auto; width: 100%;">
      <table style="border: 1px solid #dededf; height: 100%; width: 100%; table-layout: fixed; border-collapse: collapse; border-spacing: 1px; text-align: left;">
        <thead>
          <tr>
            <th
              colspan="6"
              style="text-align: center; border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;"
            >
              Поръчка
            </th>
          </tr>
          <tr>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Продукт</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Модел</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Цвят</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Размер</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Персонализация</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Цена</th>
          </tr>
        </thead>
        <tbody>
  `;

  items.forEach((item) => {
    const isTShirt = item.productType === ProductType.TSHIRT;

    productTable += `
      <tr>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
          item.title
        }</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
          isTShirt ? translateTypeToBulgarian(item.type) : 'N/A'
        }</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
          isTShirt ? translateColorToBulgarian(item.color) : 'N/A'
        }</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
          isTShirt ? mapSizeToString(item.size) : 'N/A'
        }</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
          item.customizationNotes || 'Няма'
        }</td>
        <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${item.price.toFixed(
          2
        )}лв</td>
      </tr>
    `;
  });

  productTable += `
        </tbody>
      </table>
    </div>
  `;

  const priceTable = `
    <div style="overflow: auto; width: 100%; margin-top: 20px;">
      <table style="border: 1px solid #dededf; height: 100%; width: 100%; table-layout: fixed; border-collapse: collapse; border-spacing: 1px; text-align: left;">
        <thead>
          <tr>
            <th
              colspan="3"
              style="text-align: center; border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;"
            >
              Цена
            </th>
          </tr>
          <tr>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Доставка</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Промо код</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Общо</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              totalPrice >= shipping.minimumAmount
                ? `N/A (${totalPrice.toFixed(2)} >= ${shipping.minimumAmount})`
                : `${shipping.shippingCost}лв`
            }</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              promoCode
                ? `${promoCode.name} (${promoCode.percentage}%)`
                : 'Няма'
            }</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${totalPrice.toFixed(
              2
            )}лв</td>
          </tr>
        </tbody>
      </table>
    </div>
  `;

  const shippingTable = `
    <div style="overflow: auto; width: 100%; margin-top: 20px;">
      <table style="border: 1px solid #dededf; height: 100%; width: 100%; table-layout: fixed; border-collapse: collapse; border-spacing: 1px; text-align: left;">
        <thead>
          <tr>
            <th
              colspan="4"
              style="text-align: center; border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;"
            >
              Доставка
            </th>
          </tr>
          <tr>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Име</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Телефон</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">Имейл</th>
            <th style="border: 1px solid #dededf; background-color: #eceff1; color: #000000; padding: 5px;">${
              shippingInfo.speedyOffice ? 'Офис на Speedy' : 'Личен адрес'
            }</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              shippingInfo.firstName
            } ${shippingInfo.lastName}</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              shippingInfo.phone
            }</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              shippingInfo.email
            }</td>
            <td style="border: 1px solid #dededf; background-color: #ffffff; color: #000000; padding: 5px;">${
              shippingInfo.speedyOffice ?? shippingInfo.personalAddress
            }</td>
          </tr>
        </tbody>
      </table>
    </div>
  `;

  const emailHtml = `
    <h1>Поръчка номер ${orderNumber}</h1>
    ${productTable}
    ${priceTable}
    ${shippingTable}
  `;

  const errorHtml = `
    <h1 style="color: red">Възникна проблем при плащането и поръчката не беше успешна</h1>
    ${emailHtml}
  `;

  return error ? errorHtml : emailHtml;
};

const generateOrderSummayHtml = (items: CartProduct[]) => {
  let itemsHtml = '';

  items.forEach((item) => {
    const isTShirt = item.productType === ProductType.TSHIRT;

    itemsHtml += `
      <div style="display: flex;" href="https://teniskivarna.com/products/${
        item.id
      }">
        <img 
          src="${item.image}" 
          alt="${getImageAltText(item.title)}" 
          width="${isTShirt ? 250 : 180}" 
          height="${isTShirt ? 250 : 150}"
          style="${!isTShirt ? 'padding: 40px 35px' : ''}" />
        <div>
          <p><strong>${item.title}</strong></p>
          ${
            isTShirt
              ? `<p>Цвят: ${translateColorToBulgarian(item.color)}</p>`
              : ''
          }
          ${
            isTShirt
              ? `<p>Модел: ${translateTypeToBulgarian(item.type)}</p>`
              : ''
          }
          ${isTShirt ? `<p>Размер: ${mapSizeToString(item.size)}</p>` : ''}
          <p>Цена: ${item.price}лв</p>
          <p>Персонализация: ${
            item.customizationNotes ? item.customizationNotes : 'няма'
          }</p>
        </div>
     </div>
    `;
  });

  return itemsHtml;
};

const generateCustomerOrderHtml = (
  type: OrderEmailType,
  orderNumber: string,
  shippingInfo: OrderShippingInfo,
  items: CartProduct[],
  promoCode: PromoCode | null,
  totalPrice: number,
  shipping: ShippingData
) => `
    <img 
      src="https://firebasestorage.googleapis.com/v0/b/teniski-varna.appspot.com/o/logo%2Flogo-horizontal.png?alt=media&token=5ffb7c7a-4e5a-4f4f-bd8f-a9b4efd47072" 
      alt="teniski-varna-logo" 
      height="150"
      style="align-self: center; display: block; margin-left: auto; margin-right: auto;" />

    <br />

    ${
      type === OrderEmailType.CUSTOMER_ORDER_CREATED
        ? `
        <h3 style="text-align: center;"><strong>Благодарим ви, че избрахте нашите продукти!</strong></h3>
        <h4 style="text-align: center;">Успешно направихте поръчка с номер: <strong style="font-size: 16px;">${orderNumber}</strong></h4>
      `
        : `
        <h3 style="text-align: center;"><strong>Вашата поръчка с номер <strong style="font-size: 16px;">${orderNumber}</strong> е изпратена и пътува към вас!</strong></h3>
      `
    }

    <br />

    <p><strong>Поръчани продукти:</strong></p>
    ${generateOrderSummayHtml(items)}

    <br />

    <p>Доставка: <strong style="font-size: 16px;">${
      totalPrice >= shipping.minimumAmount
        ? `Безплатно`
        : `${shipping.shippingCost}лв`
    }</strong></p>
    <p>Общо: <strong style="font-size: 16px;">${totalPrice.toFixed(
      2
    )}лв</strong></p>
    
    <br />

    <p><strong>Информация за доставка:</strong></p>
    <p>Име: ${shippingInfo.firstName} ${shippingInfo.lastName}</p>
    ${
      shippingInfo.speedyOffice
        ? `<p>Офис на Speedy: ${shippingInfo.speedyOffice}</p>`
        : ''
    }
    ${
      shippingInfo.personalAddress
        ? `<p>Личен адрес: ${shippingInfo.personalAddress}</p>`
        : ''
    }
    <p>Телефон: ${shippingInfo.phone}</p>

    <br />

    <p><strong>Ако имате въпроси оставаме насреща:</strong></p>
    <p>Телефон: 
      <strong>088 822 8975</strong>
    </p>
    <p>Имейл: 
      <strong>teniski.varna2022@gmail.com</strong>
    </p>
    <p>Адрес: 
      <strong>гр. Варна, бул. Сливница 165, ет. 0, срещу Costa кафе</strong>
    </p>
  `;

const generateFeedbackHtml = (
  name: string,
  email: string,
  phone: string,
  feedback: string
) => `
  <body style="color: #000">
    <h1><strong>Обратна връзка от клиент</strong></h1>
    <p>Име: <strong>${name === '' ? 'Не е посочено' : name}</strong></p>
    <p>Имейл: <strong>${email === '' ? 'Не е посочен' : email}</strong></p>
    <p>Телефон: <strong>${phone === '' ? 'Не е посочен' : phone}</strong></p>
    <p>Съобщение:</p>
    <h2>${feedback}</h2>
  </body>
`;
