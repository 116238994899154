import React from 'react';
import Zoom from 'react-medium-image-zoom';
import { toast } from 'react-toastify';

import 'react-medium-image-zoom/dist/styles.css';
import styled from 'styled-components';

import { Color } from '@assets/constants';
import { icons } from '@assets/icons';
import { TShirtColor } from '@containers/adminPanel/utils';
import { Cart } from '@features/cart/Cart';
import { translateTypeToBulgarian } from '@features/cart/utils';
import { ColorSelector } from '@features/details/ColorSelector';
import { CustomizationArea } from '@features/details/CustomizationArea';
import { RatingStars } from '@features/details/RatingStars';
import { SizeSelector } from '@features/details/SizeSelector';
import {
  useAppDispatch,
  useAppSelector,
  useFavorites,
  useModalClose,
  useScreenSize
} from '@hooks';
import { AppConfig } from '@models/appConfig';
// import { ReactComponent as ShippingButton } from '@assets/images/shipping.svg';
// import { ReactComponent as MaterialsButton } from '@assets/images/materials.svg';
import { Product, ProductType, TShirtSize, TShirtType } from '@models/product';
import { ScreenSize } from '@models/screen';
import { cartActions } from '@store';
import { ActivityIndicator } from '@ui/ActivityIndicator';
import { Button, ButtonSize, ButtonType } from '@ui/Button';
import { IconButton } from '@ui/IconButton';
import { Modal, ModalEnterAnimation, ModalExitAnimation } from '@ui/Modal';
import { isDev } from '@utils/environment';

import { getAvailableSizes, getImageAltText } from './utils';

interface Props {
  product: Product;
  tShirtTypes: TShirtType[];
  discountedPrice: number | null;
  selectedType: TShirtType | null;
  onSelectType: (type: TShirtType) => void;
  selectedSize: TShirtSize | null;
  onSelectSize: (size: TShirtSize) => void;
  selectedColor: TShirtColor | null;
  onSelectColor: (color: TShirtColor) => void;
  customizationNotes: string;
  onCustomizationNotesChange: (notes: string) => void;
  imageHasLoaded: boolean;
  onImageLoad: () => void;
  onGoBack: () => void;
  onShowSizeInfo: () => void;
  showSizeInfo: boolean;
  onShowShippingInfo: () => void;
  showShippingInfo: boolean;
  onShowMaterialsInfo: () => void;
  showMaterialsInfo: boolean;
  onToggleCart: () => void;
  showCart: boolean;
  appConfig: AppConfig;
}

export const DetailsContainerDesktop = ({
  tShirtTypes,
  selectedType,
  onSelectType,
  selectedSize,
  onSelectSize,
  selectedColor,
  onSelectColor,
  customizationNotes,
  onCustomizationNotesChange,
  imageHasLoaded,
  onImageLoad,
  onGoBack,
  onShowSizeInfo,
  showSizeInfo,
  onToggleCart,
  showCart,
  onShowShippingInfo,
  showShippingInfo,
  onShowMaterialsInfo,
  showMaterialsInfo,
  product,
  discountedPrice,
  appConfig
}: Props) => {
  const { getIsFavorite, addToFavorites, removeFromFavorites } = useFavorites();
  const cartItems = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const screenSize = useScreenSize();

  const { closing: sizeInfoClosing, handleClose: handleCloseSizeInfo } =
    useModalClose(() => onShowSizeInfo());
  const { closing: shippingInfoClosing, handleClose: handleCloseShippingInfo } =
    useModalClose(() => onShowShippingInfo());
  const {
    closing: materialsInfoClosing,
    handleClose: handleCloseMaterialsInfo
  } = useModalClose(() => onShowMaterialsInfo());

  const isLargeScreen = screenSize === ScreenSize.LARGE;

  const imageUrl =
    product.type === ProductType.TSHIRT
      ? // @ts-ignore
        product.images[selectedType][selectedColor].url
      : product.image?.url;
  const imageName = getImageAltText(
    product.type === ProductType.TSHIRT
      ? // @ts-ignore
        product.images[selectedType][selectedColor].name
      : // @ts-ignore
        product.image.name
  );

  const {
    transactions: { areDisabled }
  } = appConfig;
  const areTransactionsDisabled = areDisabled && !isDev();

  const isMug = product.type === ProductType.MUG;
  const isFavorite = getIsFavorite(product);

  const favoriteHandler = () => {
    isFavorite ? removeFromFavorites(product) : addToFavorites(product);
  };

  const addToCartHandler = () => {
    if (product.type === ProductType.TSHIRT && !selectedColor) {
      return toast.error('Моля изберете цвят за вашата тениска.');
    }
    if (product.type === ProductType.TSHIRT && !selectedSize) {
      return toast.error('Моля изберете размер за вашата тениска.');
    }

    dispatch(
      cartActions.addToCart({
        product,
        selectedColor,
        image: imageUrl,
        selectedSize,
        discountedPrice,
        selectedType,
        customizationNotes
      })
    );

    toast.success(`Тениската беше успешно добавена в кошницата.`, {
      icon: (
        <PartyIcon src={require('../../assets/images/party-popperIcon.png')} />
      ),
      onClick: () => {
        onToggleCart();
        toast.dismiss();
      }
    });
  };

  const descriptionTitle =
    product.type === ProductType.TSHIRT
      ? 'Тениска с щампа'
      : 'Чаша със снимка или надпис';

  return (
    <>
      <Cart
        cartItems={cartItems}
        showCart={showCart}
        onCloseCart={onToggleCart}
      />
      <Container>
        {!isLargeScreen && (
          <ActionButtonsWrapper>
            <IconButton
              icon={icons.FaChevronLeft}
              onClick={onGoBack}
              size={56}
            />
          </ActionButtonsWrapper>
        )}
        <LeftContainer>
          <ImageWrapper>
            {!imageHasLoaded && (
              <ActivityIndicator size={100} color={Color.ACCENT} />
            )}
            <Zoom
              zoomMargin={isMug ? 50 : -100}
              IconUnzoom={() => <icons.FaTimes size={22} />}
            >
              <Image
                src={imageUrl}
                alt={imageName}
                isMug={isMug}
                onLoad={() => onImageLoad()}
                loaded={imageHasLoaded}
              />
            </Zoom>
          </ImageWrapper>
          {!isMug && (
            <>
              <DescriptionTitle>{descriptionTitle}</DescriptionTitle>
              <Description>{product.description}</Description>
            </>
          )}
        </LeftContainer>
        <RightContainer>
          <HeaderWrapper>
            <TitleWrapper>
              <Title>{product.title}</Title>
              <RatingStars minWidth={80} />
            </TitleWrapper>
          </HeaderWrapper>

          {isMug && (
            <>
              <DescriptionTitle>{descriptionTitle}</DescriptionTitle>
              <Description>{product.description}</Description>
            </>
          )}

          {product.type !== ProductType.MUG && (
            <>
              <SelectTitle>Изберете модел</SelectTitle>
              <TypeSelector>
                {tShirtTypes.map((type, index) => (
                  <TypeButton
                    onClick={() => onSelectType(type)}
                    selected={selectedType === type}
                    key={index}
                  >
                    {translateTypeToBulgarian(type)}
                  </TypeButton>
                ))}
              </TypeSelector>

              <SelectTitle>Изберете цвят</SelectTitle>
              <TilesWrapper>
                {selectedType && (
                  <ColorSelector
                    colors={product.images[selectedType]}
                    selectedColor={selectedColor}
                    onSelectColor={(color) => onSelectColor(color)}
                  />
                )}
              </TilesWrapper>
              <SizeWrapper onClick={onShowSizeInfo}>
                <SelectSizeTitle>Изберете размер</SelectSizeTitle>
                <SizeChartButton
                  src={require('../../assets/images/size-chart.png')}
                />
              </SizeWrapper>
              <TilesWrapper>
                {selectedType === TShirtType.MEN && (
                  <SizeSelector
                    availableSizes={getAvailableSizes(
                      product.sizes,
                      selectedColor,
                      TShirtType.MEN
                    )}
                    selectedSize={selectedSize}
                    onSelectSize={(size) => onSelectSize(size)}
                  />
                )}
                {selectedType === TShirtType.WOMEN && (
                  <SizeSelector
                    availableSizes={getAvailableSizes(
                      product.sizes,
                      selectedColor,
                      TShirtType.WOMEN
                    )}
                    selectedSize={selectedSize}
                    onSelectSize={(size) => onSelectSize(size)}
                  />
                )}
                {selectedType === TShirtType.KIDS && (
                  <SizeSelector
                    availableSizes={getAvailableSizes(
                      product.sizes,
                      selectedColor,
                      TShirtType.KIDS
                    )}
                    selectedSize={selectedSize}
                    onSelectSize={(size) => onSelectSize(size)}
                  />
                )}
                {selectedType === TShirtType.OVERSIZED && (
                  <SizeSelector
                    availableSizes={getAvailableSizes(
                      product.sizes,
                      selectedColor,
                      TShirtType.OVERSIZED
                    )}
                    selectedSize={selectedSize}
                    onSelectSize={(size) => onSelectSize(size)}
                  />
                )}
              </TilesWrapper>
            </>
          )}

          <CustomizationArea
            productType={product.type}
            value={customizationNotes}
            onChange={onCustomizationNotesChange}
          />

          <PriceWrapper>
            <Price discounted={!!discountedPrice}>{product.price}лв</Price>
            {discountedPrice && (
              <DiscountedPrice>{discountedPrice}лв</DiscountedPrice>
            )}
          </PriceWrapper>

          <CtaWrapper>
            {!areTransactionsDisabled && (
              <Button
                label="Добави в кошницата"
                size={ButtonSize.MEDIUM}
                onClick={addToCartHandler}
                additionalStyles={`width: 100%;`}
              />
            )}
            <Button
              label={`${isFavorite ? 'Премахни от' : 'Добави в'} любими`}
              icon={{
                type: isFavorite ? icons.FaHeart : icons.FaRegHeart,
                size: 20,
                color: isFavorite ? Color.RED : Color.GRAY
              }}
              size={ButtonSize.MEDIUM}
              type={ButtonType.UNSELECTED}
              onClick={favoriteHandler}
              additionalStyles={`width: 100%;`}
            />
          </CtaWrapper>
        </RightContainer>
      </Container>
      {/* <ShippingAndMaterialsWrapper>
        <FullWidthButton onClick={() => onShowShippingInfo()}>
          <ShippingButton />
        </FullWidthButton>
        <FullWidthButton onClick={() => onShowMaterialsInfo()}>
          <MaterialsButton />
        </FullWidthButton>
      </ShippingAndMaterialsWrapper> */}
      {showSizeInfo && (
        <Modal
          closing={sizeInfoClosing}
          onClose={handleCloseSizeInfo}
          enterAnimation={ModalEnterAnimation.SLIDE_DOWN_CENTER}
          exitAnimation={ModalExitAnimation.SLIDE_RIGHT_CENTER}
          additionalStyles={`
            width: 50%;
          `}
        >
          <InfoModalWrapper>
            <img src={require('../../assets/images/size-info.png')} />
          </InfoModalWrapper>
        </Modal>
      )}
      {showShippingInfo && (
        <Modal closing={shippingInfoClosing} onClose={handleCloseShippingInfo}>
          <InfoModalWrapper>
            <h1>Shipping info</h1>
            <p>Waiting for Tomi to provide copy</p>
          </InfoModalWrapper>
        </Modal>
      )}
      {showMaterialsInfo && (
        <Modal
          closing={materialsInfoClosing}
          onClose={handleCloseMaterialsInfo}
        >
          <InfoModalWrapper>
            <h1>Materials info</h1>
            <p>Waiting for Tomi to provide copy</p>
          </InfoModalWrapper>
        </Modal>
      )}
    </>
  );
};

// const FullWidthButton = styled.div`
//   width: 100%;
//   padding: 1rem;
//   cursor: pointer;
//   border-radius: 15px;
//   background-color: ${Color.ACCENT};
//   &:hover {
//     backdrop-filter: brightness(0.8);
//   }
// `;

// const ShippingAndMaterialsWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   gap: 10px;
//   margin-top: 20px;
// `;

const ActionButtonsWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 1000;
`;

const PartyIcon = styled.img`
  width: 24px;
`;

const SizeChartButton = styled.img`
  cursor: pointer;
  width: 40px;
`;

const InfoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const SizeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
`;

const TypeButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  padding: 5px 10px;
  min-height: 48px;
  background: ${(props) => (props.selected ? Color.ACCENT : Color.LIGHT_GRAY)};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  flex-grow: 1;
  color: ${(props) => (props.selected ? Color.BLACK : Color.GRAY)};

  &:active {
    scale: 0.95;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

const PriceWrapper = styled.div`
  margin-top: 15px;
`;

const TypeSelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
`;

const Image = styled.img<{ isMug: boolean; loaded: boolean }>`
  max-height: 100%;
  max-width: 100%;
  transform: scale(1.2);
  ${({ isMug }) => isMug && 'scale: 0.85;'}
  ${({ loaded }) => !loaded && 'display: none;'}
`;

const SelectTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  color: ${Color.DARK_GRAY};
`;

const SelectSizeTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${Color.DARK_GRAY};
`;

const DiscountedPrice = styled.p`
  font-size: 35px;
  font-weight: 800;
  color: ${Color.RED};
`;

const Price = styled.p<{ discounted?: boolean }>`
  font-size: 35px;
  font-weight: 800;
  ${({ discounted }) =>
    discounted &&
    `
      font-size: 24px;
      color: ${Color.GRAY};
      text-decoration: line-through;
  `}
`;

const CtaWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;

  @media (min-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 1366px) {
    flex-direction: row;
  }
`;

const TilesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  line-height: 30px;
`;

const Description = styled.p`
  color: ${Color.GRAY};
  line-height: 1.4rem;
  margin-top: 20px;
`;

const DescriptionTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  color: ${Color.DARK_GRAY};
`;

const Title = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.3rem;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const RightContainer = styled.div`
  padding: 1.5rem;
  width: 50%;
  z-index: 100;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  min-height: 100%;

  @media (max-width: 1024px) {
    width: 100svw;
    margin-left: calc(-50vw + 50%);
    padding: 2rem;
  }
`;
