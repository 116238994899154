export interface SpeedyCity {
  name: string;
  nameEn: string;
}

export enum SpeedyOfficeType {
  APT = 'APT',
  OFFICE = 'OFFICE'
}

export interface SpeedyOffice {
  id: number;
  name: string;
  city: string;
  address: string;
  type: SpeedyOfficeType;
}
