import { toast } from 'react-toastify';

import { collection, getDocs } from 'firebase/firestore';

import { PromoCode } from '@models/promoCode';
import { useMutation } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const useCheckPromoCode = () => {
  const promoCodesCollectionRef = collection(db, 'promoCodes');

  const checkPromoCode = async (promoCode: string) => {
    try {
      const data = await getDocs(promoCodesCollectionRef);
      const promoCodes = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      })) as PromoCode[];

      const trimmedPromoCode = promoCode.trim();

      const promoCodeExists = promoCodes.some(
        (code) => code.name === trimmedPromoCode
      );

      if (promoCodeExists) {
        return promoCodes.find((code) => code.name === trimmedPromoCode);
      }

      return null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(`💥 ${e.message}`);
      return null;
    }
  };

  return useMutation({
    mutationFn: (promoCode: string) => checkPromoCode(promoCode)
  });
};
