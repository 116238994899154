import { useMutation } from '@tanstack/react-query';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

interface ThumbnailProps {
  imageUrl: string;
  productId: string;
}

export const useCreateThumbnail = () => {
  const createThumbnail = async (props: ThumbnailProps) => {
    const url = `${REACT_APP_TENISKI_API_BASE_URL}/thumbnail/create`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(props)
      });
      const data = await response.json();
      return data;
    } catch (e: unknown) {
      console.log('error', (e as Error).message);
    }
  };

  return useMutation({
    mutationFn: (props: ThumbnailProps) => createThumbnail(props)
  });
};
