import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { UpdateProductContainer } from '@containers/adminPanel/UpdateProductContainer';
import { ProductList } from '@features/home/ProductList';
import { Label } from '@models/label';
import { ProductPage } from '@models/product';
import { useLabels, useProducts } from '@queries';
import { ActivityIndicator } from '@ui/ActivityIndicator';
import { EdittableAndSelectableItems } from '@ui/EdittableAndSelectableItems';
import { EmptyProductList } from '@ui/EmptyList';
import { Input } from '@ui/Input';

export const ManageProductsContainer = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLabelId, setSelectedLabelId] = useState<string>();
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null
  );

  const { data: productsData, isPending: isFetchingProducts } = useProducts({
    searchTerm,
    labelId: selectedLabelId,
    paginated: false
  });
  const { data: labels, isPending: isFetchingLabels } = useLabels();

  const mapPagesToProducts = useCallback(
    (pages: ProductPage[]) => pages.flatMap((page) => page.products),
    [productsData]
  );

  const products = productsData ? mapPagesToProducts(productsData.pages) : [];

  const unlabelledLabel: Label = {
    id: 'unlabelled',
    name: 'UNLABELLED',
    index: 0
  };

  const handleLabelSelection = (labelId: string) => {
    if (labelId === selectedLabelId) {
      return setSelectedLabelId(undefined);
    }

    setSearchTerm('');
    setSelectedLabelId(labelId);
  };

  const handleProductSelection = (productId: string) => {
    setSelectedProductId(productId);
  };

  const handleBackToAllProducts = () => setSelectedProductId(null);

  return (
    <Wrapper>
      <Header>
        <Title>Manage products</Title>
        {selectedProductId && (
          <BackLink onClick={handleBackToAllProducts}>
            Back to all products
          </BackLink>
        )}
      </Header>
      {!selectedProductId && (
        <Input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
      {!selectedProductId && isFetchingLabels && (
        <ActivityIndicatorWrapper>
          <ActivityIndicator size={100} color={Color.ACCENT} />
        </ActivityIndicatorWrapper>
      )}
      {!selectedProductId && !isFetchingLabels && (
        <EdittableAndSelectableItems
          items={[unlabelledLabel, ...labels]}
          selectedItemIds={selectedLabelId ? [selectedLabelId] : []}
          handleSelectItem={(labelId) => handleLabelSelection(labelId)}
          isFetchingItems={isFetchingLabels}
          selective
        />
      )}
      {!selectedProductId && isFetchingProducts && (
        <ActivityIndicatorWrapper>
          <ActivityIndicator size={100} color={Color.ACCENT} />
        </ActivityIndicatorWrapper>
      )}
      {!selectedProductId && !isFetchingProducts && (
        <ProductList
          productsData={productsData}
          cols={5}
          onSelectProductToEdit={handleProductSelection}
        />
      )}
      {!selectedProductId && selectedLabelId && products.length === 0 && (
        <EmptyProductList textColor={Color.WHITE} />
      )}
      {selectedProductId && (
        <UpdateProductContainer
          productId={selectedProductId}
          handleBackToAllProducts={handleBackToAllProducts}
        />
      )}
    </Wrapper>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const BackLink = styled.p`
  color: ${Color.ACCENT};
  font-size: 12px;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 24px;
  color: ${Color.WHITE};
`;

const ActivityIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;

const Wrapper = styled.div`
  padding-top: 20px;
  min-height: 500px;
`;
