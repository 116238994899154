import { configureStore } from '@reduxjs/toolkit';

import { cartSlice } from './cartSlice';
import { configSlice } from './configSlice';
import { favoritesSlice } from './favoritesSlice';
import { labelsSlice } from './labelsSlice';
import { searchSlice } from './searchSlice';

export const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    favorites: favoritesSlice.reducer,
    search: searchSlice.reducer,
    labels: labelsSlice.reducer,
    appConfig: configSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export * from './cartSlice';
export * from './configSlice';
export * from './favoritesSlice';
export * from './labelsSlice';
export * from './searchSlice';
export * from './utils';
