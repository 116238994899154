import React from 'react';

import styled, { css } from 'styled-components';

interface Props {
  additionalStyles?: string;
}

export const Divider = ({ additionalStyles }: Props) => (
  <DividerComponent additionalStyles={additionalStyles} />
);

const DividerComponent = styled.div<{ additionalStyles?: string }>`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;

  ${({ additionalStyles }) => css`
    ${additionalStyles}
  `}
`;
