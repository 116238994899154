import { Product } from '@models/product';
import { useMutation } from '@tanstack/react-query';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

export const useIndexProduct = () => {
  const indexProduct = async (product: Product) => {
    try {
      const res = await fetch(
        `${REACT_APP_TENISKI_API_BASE_URL}/algolia/indexProduct`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(product)
        }
      );

      const data = await res.json();
      console.log(data);
    } catch (error) {
      console.error('Error indexing products:', error);
    }
  };

  return useMutation({
    mutationFn: (product: Product) => indexProduct(product)
  });
};
