import { toast } from 'react-toastify';

import { collection, getDocs } from 'firebase/firestore';

import { Discount } from '@models/discount';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const useActiveDiscounts = () => {
  const discountsCollectionRef = collection(db, 'discounts');

  const getActiveDiscounts = async () => {
    try {
      const data = await getDocs(discountsCollectionRef);
      const activeDiscounts: Discount[] = [];
      data.docs.forEach((doc) => {
        if (doc.data().active) {
          activeDiscounts.push({ ...doc.data(), id: doc.id } as Discount);
        }
      });

      return activeDiscounts;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(`💥 ${e.message}`);
      return [];
    }
  };

  return useQuery({
    queryKey: ['activeDiscounts'],
    queryFn: getActiveDiscounts,
    staleTime: 1000 * 60 * 60 // 1 hour
  });
};
