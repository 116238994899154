import React, { useState } from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { useCustomNavigate, useModalClose } from '@hooks';
import { useAdminEmail } from '@mutations';
import { Button } from '@ui/Button';
import { Input } from '@ui/Input';
import { Modal, ModalEnterAnimation, ModalExitAnimation } from '@ui/Modal';
import { Textarea } from '@ui/Textarea';
import { sendFeedbackEmailToAdmin } from '@utils/emailUtils';

enum Status {
  SUCCESS,
  ERROR
}

export const FeedbackPage = () => {
  const [feedback, setFeedback] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>(Status.ERROR);

  const { mutateAsync: sendAdminEmail, isPending: isSendingEmail } =
    useAdminEmail();
  const navigate = useCustomNavigate();
  const { closing, handleClose } = useModalClose(() => setShowModal(false));

  const sendFeedback = async () => {
    const response = await sendFeedbackEmailToAdmin(
      sendAdminEmail,
      feedback,
      name,
      email,
      phone
    );

    if (response.status === 200) {
      setStatus(Status.SUCCESS);
    } else {
      setStatus(Status.ERROR);
    }

    setShowModal(true);
    resetForm();
  };

  const resetForm = () => {
    setFeedback('');
    setName('');
    setEmail('');
    setPhone('');
  };

  return (
    <Wrapper>
      <Text>Обрана връзка</Text>
      <SmallText>Моля кажете ни какво можем да подобрим</SmallText>
      <Textarea
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder={'Какво мислите...'}
        height={150}
      />
      <InputsWrapper>
        <InputWrapper>
          <Label>Име (незадължително)</Label>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={'Име'}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Имейл (незадължително)</Label>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={'email@gmail.com'}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Телефон (незадължително)</Label>
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type={'number'}
            maxLength={10}
            placeholder={'08XXXXXXXX'}
          />
        </InputWrapper>
      </InputsWrapper>
      <Button
        label={'Изпрати'}
        disabled={feedback.trim() === ''}
        onClick={sendFeedback}
        loading={isSendingEmail}
      />

      {showModal && (
        <Modal
          onClose={handleClose}
          enterAnimation={ModalEnterAnimation.SLIDE_DOWN_CENTER}
          exitAnimation={ModalExitAnimation.SLIDE_RIGHT_CENTER}
          closing={closing}
        >
          <SuccessWrapper>
            <Text>
              {status === Status.SUCCESS
                ? 'Благодарим Ви за обратната връзка!'
                : 'Нещо се обърка :('}
            </Text>
            <SmallText>
              {status === Status.SUCCESS
                ? 'Получихме вашето съобщение и ще направим всичко възможно да подобрим сайта си.'
                : 'Моля опитайте отново или се свържете с нас на посочените контакти.'}
            </SmallText>
            <ReturnButtonWrapper>
              <Button
                label={'Обратно към Teniski Varna'}
                onClick={() => navigate('/')}
              />
            </ReturnButtonWrapper>
          </SuccessWrapper>
        </Modal>
      )}
    </Wrapper>
  );
};

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 2rem;
`;

const ReturnButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Label = styled.p`
  font-size: 0.8rem;
  color: ${Color.DARK_GRAY};
  margin-left: 0.6rem;
  margin-bottom: 0.2rem;
`;

const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
  margin: 1rem 0 2rem 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SmallText = styled.p`
  font-size: 1rem;
  color: ${Color.DARK_GRAY};
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Text = styled.p`
  font-size: 1.5rem;
  color: ${Color.DARK_GRAY};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;
