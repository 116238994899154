import React from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { ReactComponent as LogoSvg } from '@assets/images/logo-horizontal.svg';
import { ReactComponent as TrafficConeSvg } from '@assets/images/traffic-cone.svg';
import { Button } from '@ui/Button';

export const MaintenancePage = () => (
  <Wrapper>
    <Logo />
    <TrafficCone />
    <Message>
      <Text>В момента извършваме поддръжка на сайта...</Text>
      <SmallText>
        Съжаляваме за неудобството, моля опитайте пак по-късно.
      </SmallText>

      <Button label={'Опитай пак'} onClick={() => window.location.reload()} />
    </Message>
  </Wrapper>
);

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.WHITE};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const SmallText = styled.p`
  font-size: 16px;
  color: ${Color.DARK_GRAY};
  text-align: center;
  margin-bottom: 20px;
`;

const Text = styled.h1`
  font-size: 20px;
  font-weight: bold;
  color: ${Color.DARK_GRAY};
  text-align: center;
  margin-bottom: 10px;
`;

const Logo = styled(LogoSvg)`
  width: 200px;
`;

const TrafficCone = styled(TrafficConeSvg)`
  width: 200px;
  height: 200px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 1rem;
  height: 100svh;
  background-color: ${Color.LIGHT_GRAY};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffc462' fill-opacity='0.45' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
    filter: blur(2px);
  }

  & > * {
    position: relative;
    z-index: 2;
  }
`;
