export enum DiscountType {
  GLOBAL = 'global',
  STANDARD = 'standard'
}

export interface Discount {
  id: string;
  name: string;
  type: DiscountType;
  percentage: number;
  labelIds: string[];
  active: boolean;
}
