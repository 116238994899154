import { toast } from 'react-toastify';

import { collection, getDocs } from 'firebase/firestore';

import { PromoCode } from '@models/promoCode';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const usePromoCodes = () => {
  const promoCodesCollectionRef = collection(db, 'promoCodes');

  const getPromoCodes = async () => {
    try {
      const data = await getDocs(promoCodesCollectionRef);
      const promoCodes = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      })) as PromoCode[];

      return promoCodes;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(`💥 ${e.message}`);
      return [];
    }
  };

  return useQuery({
    queryKey: ['promoCodes'],
    queryFn: getPromoCodes,
    initialData: [],
    staleTime: 1000 * 60 * 60, // 1 hour
    initialDataUpdatedAt: 0
  });
};
