import { SpeedyOffice, SpeedyOfficeType } from '@models/speedy';

import { MyPosProduct } from '../../../domain/mappers/cartProductMapper';

export enum CheckoutField {
  CUSTOMER_FIRST_NAME = 'firstName',
  CUSTOMER_LAST_NAME = 'lastName',
  CUSTOMER_PHONE = 'phone',
  CUSTOMER_EMAIL = 'email',
  CUSTOMER_CITY = 'city',
  CUSTOMER_STREET = 'street',
  CUSTOMER_ADDITIONAL_NOTES = 'additionalNotes',
  CUSTOMER_SPEEDY_OFFICE = 'speedyOffice',
  DELIVERY_OPTION = 'deliveryOption'
}

export const saveCustomerDataToLocalStorage = (
  customerData: { field: CheckoutField; value: string }[]
) => {
  for (const { field, value } of customerData) {
    localStorage.setItem(
      'customerData',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('customerData') || '{}'),
        [field]: value
      })
    );
  }
};

export const getCustomerDataFromLocalStorage = () =>
  JSON.parse(localStorage.getItem('customerData') || '{}');

export const getTotalPrice = (items: MyPosProduct[]) =>
  Number(items.reduce((acc, item) => acc + item.price, 0));

export const getDiscountedPrice = (
  price: number,
  discount?: number
): number => {
  const discountedPrice = Number(
    discount ? price - price * (discount / 100) : price
  );
  return Number(discountedPrice.toFixed(2));
};

export const getSpeedyOfficeName = (office: SpeedyOffice) =>
  `[${office.id}] ${office.address} ${
    office.type === SpeedyOfficeType.APT ? '(АВТОМАТ)' : ''
  }`;
