import React from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { Order } from '@models/order';
import {
  DEFAULT_OPTIONS,
  getTheme
} from '@table-library/react-table-library/chakra-ui';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';

interface Props {
  order: Order;
}

export const OrderDetailsTable = ({ order }: Props) => {
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = (numberOfCols: number) =>
    useTheme({
      Table: `
      border-radius: 10px;
      --data-table-library_grid-template-columns: repeat(${numberOfCols}, max-content);
    `
    });
  const shippingTheme = useTheme([chakraTheme, customTheme(4)]);
  const priceTheme = useTheme([chakraTheme, customTheme(3)]);

  const data = { nodes: [order] };

  const SHIPPING_COLUMNS = [
    {
      label: 'Name',
      renderCell: ({ shippingInfo }: Order) =>
        `${shippingInfo.firstName} ${shippingInfo.lastName}`
    },
    {
      label: 'Phone',
      renderCell: ({ shippingInfo }: Order) => shippingInfo.phone
    },
    {
      label: 'Email',
      renderCell: ({ shippingInfo }: Order) => shippingInfo.email
    },
    {
      label: order.shippingInfo.speedyOffice
        ? 'Speedy office'
        : 'Personal address',
      renderCell: ({ shippingInfo }: Order) =>
        shippingInfo.speedyOffice ?? shippingInfo.personalAddress
    }
  ];

  const PRICE_COLUMNS = [
    {
      label: 'Shipping cost',
      renderCell: ({ myPosData, shippingInfo }: Order) =>
        Number(myPosData.Amount) >= shippingInfo.minimumAmount
          ? `Free (${myPosData.Amount} >= ${shippingInfo.minimumAmount})`
          : `${shippingInfo.shippingCost} BGN`
    },
    {
      label: 'Promo code',
      renderCell: ({ promoCode }: Order) =>
        promoCode ? `${promoCode.name} (${promoCode.percentage}%)` : 'N/A'
    },
    {
      label: 'Total',
      renderCell: ({ myPosData }: Order) =>
        `${Number(myPosData.Amount).toFixed(2)} ${myPosData.Currency}`
    }
  ];

  return (
    <TableWrapper>
      <Text>Shipping</Text>
      <CompactTable
        columns={SHIPPING_COLUMNS}
        data={data}
        theme={shippingTheme}
        layout={{ custom: true, horizontalScroll: true }}
      />
      <Text>Price</Text>
      <CompactTable
        columns={PRICE_COLUMNS}
        data={data}
        theme={priceTheme}
        layout={{ custom: true, horizontalScroll: true }}
      />
    </TableWrapper>
  );
};

const Text = styled.p`
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-left: 1rem;
`;

const TableWrapper = styled.div`
  width: 100%;
  background-color: ${Color.WHITE};
  border-radius: 10px;
`;
