import { toast } from 'react-toastify';

import { doc, getDoc } from 'firebase/firestore';

import { ShippingData } from '@models/shipping';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

const defaultShippingData: ShippingData = {
  shippingCost: 0,
  minimumAmount: 0
};

export const useShipping = () => {
  const getShipping = async () => {
    try {
      const shippingRef = doc(db, 'shipping', '1');
      const shipping = await getDoc(shippingRef);

      if (shipping.exists()) {
        return {
          shippingCost: shipping.data().shippingCost,
          minimumAmount: shipping.data().minimumAmount
        } as ShippingData;
      }

      return defaultShippingData;
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
      return defaultShippingData;
    }
  };

  return useQuery<ShippingData>({
    queryKey: ['shipping'],
    queryFn: getShipping,
    initialData: defaultShippingData
  });
};
