import React from 'react';
import { IconType } from 'react-icons';

import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Color } from '@assets/constants';
import { ActivityIndicator } from '@ui/ActivityIndicator';

interface Icon {
  type: IconType;
  size: number;
  color: Color;
}

interface Props {
  label: string;
  icon?: Icon;
  onClick?: () => void;
  backgroundColor?: Color;
  type?: ButtonType;
  size?: ButtonSize;
  loading?: boolean;
  disabled?: boolean;
  additionalStyles?: string;
}

interface ButtonContainerProps {
  backgroundColor?: Color;
  type?: ButtonType;
  size?: FlattenSimpleInterpolation;
  additionalStyles?: string;
}

export enum ButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  UNSELECTED = 'UNSELECTED'
}

export enum ButtonSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export const Button = ({
  label,
  icon,
  backgroundColor = Color.ACCENT,
  type = ButtonType.PRIMARY,
  size = ButtonSize.MEDIUM,
  loading,
  onClick,
  disabled,
  additionalStyles
}: Props) =>
  ButtonContainer({
    label,
    icon,
    backgroundColor,
    type,
    size,
    loading,
    onClick,
    disabled,
    additionalStyles
  });

const ButtonContainer = ({
  label,
  icon,
  backgroundColor = Color.ACCENT,
  type = ButtonType.PRIMARY,
  size = ButtonSize.MEDIUM,
  loading,
  onClick,
  disabled,
  additionalStyles
}: Props) => {
  const buttonSize = getButtonSize(size);
  const iconElement = icon
    ? React.createElement(icon.type, {
        size: icon.size,
        style: { color: icon.color }
      })
    : null;

  switch (type) {
    case ButtonType.PRIMARY:
      return (
        <ButtonPrimary
          backgroundColor={backgroundColor}
          size={buttonSize}
          onClick={onClick}
          disabled={disabled}
          additionalStyles={additionalStyles}
        >
          {loading ? (
            <ActivityIndicator
              size={getActivityIndicatorSize(size)}
              color={Color.WHITE}
            />
          ) : (
            <>
              {iconElement && <IconWrapper>{iconElement}</IconWrapper>}
              {label}
            </>
          )}
        </ButtonPrimary>
      );
    case ButtonType.SECONDARY:
      return (
        <ButtonSecondary
          backgroundColor={backgroundColor}
          size={buttonSize}
          onClick={onClick}
          disabled={disabled}
          additionalStyles={additionalStyles}
        >
          {iconElement && <IconWrapper>{iconElement}</IconWrapper>}
          {label}
        </ButtonSecondary>
      );
    case ButtonType.UNSELECTED:
      return (
        <ButtonUnselected
          backgroundColor={backgroundColor}
          size={buttonSize}
          onClick={onClick}
          disabled={disabled}
          additionalStyles={additionalStyles}
        >
          {iconElement && <IconWrapper>{iconElement}</IconWrapper>}
          {label}
        </ButtonUnselected>
      );
  }
};

const getActivityIndicatorSize = (size: ButtonSize): number => {
  switch (size) {
    case ButtonSize.SMALL:
      return 10;
    case ButtonSize.MEDIUM:
      return 20;
    case ButtonSize.LARGE:
      return 30;
  }
};

const getButtonSize = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.SMALL:
      return css`
        min-height: 30px;
        min-width: 80px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 12px;
        font-size: 12px;
      `;
    case ButtonSize.MEDIUM:
      return css`
        min-height: 55px;
        min-width: 120px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 16px;
        font-size: 16px;
      `;
    case ButtonSize.LARGE:
      return css`
        min-height: 65px;
        min-width: 200px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 20px;
        font-size: 20px;
      `;
  }
};

const ButtonUnselected = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  min-width: max-content;
  background: ${Color.LIGHT_GRAY};
  color: ${Color.MEDIUM_GRAY};
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  font-weight: 500;
  ${(props) =>
    !props.disabled &&
    `
    &:active {
      filter: brightness(0.9);
      scale: 0.95;
    }
    &:hover {
      filter: brightness(0.9);
    }
  `}
  ${(props) => props.disabled && `opacity: 0.7;`}
  ${(props) => props.size}
  ${(props) =>
    css`
      ${props.additionalStyles}
    `}
`;

const ButtonSecondary = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  min-width: max-content;
  background: transparent;
  border: 2px solid ${(props) => props.backgroundColor};
  color: ${Color.WHITE};
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  white-space: nowrap;
  padding-right: 20px;
  font-weight: 500;
  ${(props) =>
    !props.disabled &&
    `
    &:active {
      filter: brightness(0.9);
      scale: 0.95;
    }
    &:hover {
      filter: brightness(0.9);
    }
  `}
  ${(props) => props.disabled && `opacity: 0.7;`}
  ${(props) => props.size}
  ${(props) =>
    css`
      ${props.additionalStyles}
    `}
`;

const ButtonPrimary = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  min-width: max-content;
  white-space: nowrap;
  background: ${(props) => props.backgroundColor};
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  color: ${Color.BLACK};
  ${(props) =>
    !props.disabled &&
    `
    &:active {
      filter: brightness(0.9);
      scale: 0.95;
    }
    &:hover {
      filter: brightness(0.9);
    }
  `}
  ${(props) => props.disabled && `opacity: 0.7;`}
  ${(props) => props.size}
  ${(props) =>
    css`
      ${props.additionalStyles}
    `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;
