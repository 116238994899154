import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { icons } from '@assets/icons';
import { useCustomNavigate } from '@hooks';

export const PaymentErrorPage = () => {
  const navigate = useCustomNavigate();
  const { state } = useLocation();

  useLayoutEffect(() => {
    if (!state || !state.fromCheckout) {
      return navigate('/');
    }
  }, []);

  const onGoBack = () => navigate('/');

  return (
    <Wrapper>
      <icons.TbMoodSadDizzy size={200} color={Color.GRAY} />
      <Text>Нещо се обърка...</Text>
      <SmallText>
        Нещо се обърка и не успяхме да обработим плащането Ви. Моля, опитайте
        отново!
      </SmallText>
      <ReturnButtonWrapper onClick={onGoBack}>
        <icons.FaChevronLeft size={14} color={Color.DARK_GRAY} />
        <ReturnButtonText>Обратно към Teniski Varna</ReturnButtonText>
      </ReturnButtonWrapper>
    </Wrapper>
  );
};

const ReturnButtonText = styled.p`
  font-size: 0.9rem;
  color: ${Color.DARK_GRAY};
  text-decoration: underline;
  cursor: pointer;
`;

const ReturnButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const SmallText = styled.p`
  text-align: center;
  color: ${Color.DARK_GRAY};
`;

const Text = styled.p`
  font-size: 1.2rem;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
`;
