export enum Breakpoint {
  small = 450,
  medium = 768,
  large = 1024
}

export enum ScreenSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}
