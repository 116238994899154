import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

export const useSpeedyCitiesByName = () => {
  const findCitiesByName = async (searchTerm: string) => {
    const url = `${REACT_APP_TENISKI_API_BASE_URL}/speedy/findCitiesByName`;

    try {
      const response = await fetch(url, {
        headers: {
          searchterm: searchTerm
        }
      });
      const data = await response.json();
      return data;
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    }
  };

  return useMutation({
    mutationFn: (searchTerm: string) => findCitiesByName(searchTerm)
  });
};
