import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import {
  defaultImageDetails,
  getDiscountedPrice,
  getDiscountForProduct,
  TShirtColor
} from '@containers/adminPanel/utils';
import { DetailsContainer } from '@containers/Details';
import { TShirtSliderImage } from '@containers/Details/utils';
import { useAppSelector } from '@hooks';
import {
  ImagesKids,
  ImagesMen,
  ImagesOversized,
  ImagesWomen,
  ProductType,
  TShirt,
  TShirtSize,
  TShirtType
} from '@models/product';
import { useProductViews } from '@mutations';
import { useActiveDiscounts, useProductById } from '@queries';
import { ActivityIndicator } from '@ui/ActivityIndicator';
import { EmptyProductList } from '@ui/EmptyList';

export const DetailsPage = () => {
  const [images, setImages] = useState<TShirtSliderImage[]>([]);
  const [tShirtTypes, setTShirtTypes] = useState<TShirtType[]>([]);
  const [selectedType, setSelectedType] = useState<TShirtType | null>(null);
  const [selectedSize, setSelectedSize] = useState<TShirtSize | null>(null);
  const [selectedColor, setSelectedColor] = useState<TShirtColor>(
    TShirtColor.WHITE
  );
  const [customizationNotes, setCustomizationNotes] = useState<string>('');
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null);
  const [imageHasLoaded, setImageHasLoaded] = useState<boolean>(false);
  const [showSizeInfo, setShowSizeInfo] = useState<boolean>(false);
  const [showShippingInfo, setShowShippingInfo] = useState<boolean>(false);
  const [showMaterialsInfo, setShowMaterialsInfo] = useState<boolean>(false);
  const [showCart, setShowCart] = useState<boolean>(false);
  const { productId } = useParams();
  const { state } = useLocation();

  const appConfig = useAppSelector((state) => state.appConfig);

  const { data: product, isPending: isFetchingProduct } = useProductById(
    productId ?? ''
  );
  const { data: activeDiscounts, isPending: isFetchingDiscounts } =
    useActiveDiscounts();
  const { mutateAsync: updateProductViews } = useProductViews(productId ?? '');

  const mapImagesToSlides = useCallback(
    (
      images: ImagesMen | ImagesWomen | ImagesKids | ImagesOversized
    ): TShirtSliderImage[] =>
      Object.entries(images)
        .filter((image) => image[1])
        .map((image) => ({
          color: image[0] as TShirtColor,
          name: image[1].name,
          url: image[1].url
        })),
    [images]
  );

  const getTShirtTypes = useCallback(
    (product: TShirt) => {
      const types = [];

      // Use this implementation to retain the order of the types
      if (Object.values(product.images.men).some((url) => url)) {
        types.push(TShirtType.MEN);
      }
      if (Object.values(product.images.women).some((url) => url)) {
        types.push(TShirtType.WOMEN);
      }
      if (Object.values(product.images.kids).some((url) => url)) {
        types.push(TShirtType.KIDS);
      }
      if (Object.values(product.images.oversized).some((url) => url)) {
        types.push(TShirtType.OVERSIZED);
      }

      return types;
    },
    [product]
  );

  const initProduct = useCallback(() => {
    if (!product) return;

    const discount = getDiscountForProduct(product, activeDiscounts ?? []);
    const discountedPrice = getDiscountedPrice(product.price, discount);

    setDiscountedPrice(discountedPrice ?? null);

    if (product.type === ProductType.TSHIRT) {
      const tShirtTypes = getTShirtTypes(product);
      const selectedType =
        state && state.type ? state.type : product.thumbnail.type;
      const selectedColor =
        state && state.color ? state.color : product.thumbnail.color;

      const mappedImages = mapImagesToSlides(
        product.images[selectedType as TShirtType]
      );

      setTShirtTypes(tShirtTypes as TShirtType[]);
      setSelectedType(selectedType);
      setSelectedColor(selectedColor);
      setImages(mappedImages);
    } else {
      setSelectedType(TShirtType.MEN);
    }
  }, [product, activeDiscounts, state]);

  useEffect(() => {
    initProduct();
  }, [product, activeDiscounts, initProduct]);

  useEffect(() => {
    updateProductViews();
  }, []);

  const goBack = () => window.history.back();

  const selectColor = (color: TShirtColor, isMobile?: boolean) => {
    if (
      selectedType === TShirtType.MEN &&
      (selectedColor === TShirtColor.WHITE ||
        selectedColor === TShirtColor.BLACK) &&
      selectedSize === TShirtSize.XXXL
    ) {
      setSelectedSize(null);
    }

    if (
      selectedType === TShirtType.KIDS &&
      (selectedColor === TShirtColor.WHITE ||
        selectedColor === TShirtColor.BLACK) &&
      selectedSize === TShirtSize.K98
    ) {
      setSelectedSize(null);
    }

    setSelectedColor(color);

    if (!isMobile && selectedColor !== color) {
      setImageHasLoaded(false);
    }
  };

  const selectType = (type: TShirtType) => {
    if (selectedType === type) {
      return;
    }

    setImageHasLoaded(false);
    setSelectedType(type);

    const images = product
      ? (product as TShirt).images[type]
      : defaultImageDetails[type];
    const mappedImages = mapImagesToSlides(images);

    setSelectedColor(getFirstAvailableColor(images));
    setImages(mappedImages);
  };

  const getFirstAvailableColor = (
    images: ImagesMen | ImagesWomen | ImagesKids | ImagesOversized
  ) => {
    for (const [color, url] of Object.entries(images)) {
      if (url) {
        return color as TShirtColor;
      }
    }
    return TShirtColor.WHITE;
  };

  const onShowShippingInfo = () => {
    setShowMaterialsInfo(false);
    setShowShippingInfo((state) => !state);
  };

  const onShowMaterialsInfo = () => {
    setShowShippingInfo(false);
    setShowMaterialsInfo((state) => !state);
  };

  if (isFetchingProduct || isFetchingDiscounts) {
    return (
      <ActivityIndicatorWrapper>
        <ActivityIndicator size={100} color={Color.ACCENT} />
      </ActivityIndicatorWrapper>
    );
  }

  return (
    <>
      {product && selectedType ? (
        <DetailsContainer
          images={images}
          tShirtTypes={tShirtTypes}
          selectedType={selectedType}
          onSelectType={selectType}
          selectedSize={selectedSize}
          onSelectSize={setSelectedSize}
          selectedColor={selectedColor}
          onSelectColor={(color, isMobile) => selectColor(color, isMobile)}
          customizationNotes={customizationNotes}
          onCustomizationNotesChange={(notes) => setCustomizationNotes(notes)}
          imageHasLoaded={imageHasLoaded}
          onImageLoad={() => setImageHasLoaded(true)}
          onGoBack={goBack}
          onShowSizeInfo={() => setShowSizeInfo((state) => !state)}
          showSizeInfo={showSizeInfo}
          onToggleCart={() => setShowCart((state) => !state)}
          showCart={showCart}
          onShowShippingInfo={onShowShippingInfo}
          showShippingInfo={showShippingInfo}
          onShowMaterialsInfo={onShowMaterialsInfo}
          showMaterialsInfo={showMaterialsInfo}
          product={product}
          discountedPrice={discountedPrice}
          appConfig={appConfig}
        />
      ) : (
        <NotFoundWrapper>
          <EmptyProductList
            text={'Не успяхме да открием продуктът, който търсите...'}
            backButton
          />
        </NotFoundWrapper>
      )}
    </>
  );
};

const NotFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70svh;
  width: 100%;
`;

const ActivityIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
