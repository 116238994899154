import { useMutation } from '@tanstack/react-query';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

export const useDeleteProduct = () => {
  const deleteProduct = async (productId: string) => {
    try {
      const res = await fetch(
        `${REACT_APP_TENISKI_API_BASE_URL}/algolia/deleteProduct`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: productId })
        }
      );

      const data = await res.json();
      console.log(data);
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  return useMutation({
    mutationFn: (productId: string) => deleteProduct(productId)
  });
};
