import React from 'react';

import { HomeContainer } from '@containers/HomeContainer';
import { useAppSelector } from '@hooks';
import { useLabels, useProducts } from '@queries';

export const HomePage = () => {
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const selectedLabel = useAppSelector((state) => state.labels.selectedLabel);

  const {
    data: productsData,
    isPending: isFetchingProducts,
    hasNextPage,
    fetchNextPage
  } = useProducts({
    searchTerm,
    labelId: selectedLabel?.id,
    paginated: true
  });
  const { data: labels, isPending: isFetchingLabels } = useLabels();

  return (
    <HomeContainer
      productsData={productsData}
      labels={labels}
      onLoadMore={fetchNextPage}
      hasNextPage={hasNextPage}
      searchTerm={searchTerm === ''}
      isFetchingProducts={isFetchingProducts}
      isFetchingLabels={isFetchingLabels}
    />
  );
};
