import React from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { icons } from '@assets/icons';
import { FAQAccordion } from '@features/faq/FAQAccordion';

export const FAQPage = () => {
  return (
    <>
      <Row>
        <Title>Често задавани въпроси</Title>
        <icons.FaQuestionCircle size={30} color={Color.DARK_GRAY} />
      </Row>
      <FAQAccordion />
    </>
  );
};

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${Color.DARK_GRAY};
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1.5rem;
  width: 100%;
`;
