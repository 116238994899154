import { TShirtColor } from '@containers/adminPanel/utils';
import { TShirtSize, TShirtSizes, TShirtType } from '@models/product';

export interface TShirtSliderImage {
  color: TShirtColor;
  name: string;
  url: string;
}

export const getAvailableSizes = (
  sizes: TShirtSizes,
  selectedColor: TShirtColor | null,
  type: TShirtType
) => {
  switch (type) {
    case TShirtType.OVERSIZED:
      return sizes.oversized;
    case TShirtType.WOMEN:
      return sizes.women;
    case TShirtType.MEN:
      return selectedColor === TShirtColor.WHITE ||
        selectedColor === TShirtColor.BLACK
        ? sizes.men
        : sizes.men.filter((size) => size !== TShirtSize.XXXL);
    case TShirtType.KIDS:
      return selectedColor === TShirtColor.WHITE ||
        selectedColor === TShirtColor.BLACK
        ? sizes.kids
        : sizes.kids.filter((size) => size !== TShirtSize.K98);
  }
};

export const getImageAltText = (imageName: string) =>
  imageName.replace('.png', '');
