import { Product } from '@models/product';
import { createSlice } from '@reduxjs/toolkit';
import { getLocalFavorites } from '@store';

export type FavoritesInitialState = Product[];

const initialState: FavoritesInitialState = [];

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setFavorites: (state, { payload }) => {
      return payload;
    },
    addToFavorites: (state, { payload }) => {
      const localItems = getLocalFavorites();
      const updatedFavorites = [...state, payload];

      localItems.push(payload);
      localStorage.setItem('favorites', JSON.stringify(localItems));

      return updatedFavorites;
    },
    removeFromFavorites: (state, { payload }) => {
      const updatedFavorites = state.filter(
        (item: Product) => item.id !== payload.id
      );

      localStorage.setItem('favorites', JSON.stringify(updatedFavorites));

      return updatedFavorites;
    }
  }
});

export const favoritesActions = favoritesSlice.actions;
