import React, { useCallback, useEffect } from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { BannerSlider } from '@features/home/BannerSlider';
import { LabelSlider } from '@features/home/LabelSlider';
import { ProductList } from '@features/home/ProductList';
import { ScrollToTopButton } from '@features/home/ScrollToTopButton';
import { useAppDispatch, useAppSelector, useElementOnScreen } from '@hooks';
import { Label } from '@models/label';
import { ProductPage, ProductsData } from '@models/product';
import { labelsActions } from '@store';
import { ActivityIndicator } from '@ui/ActivityIndicator';
import { EmptyProductList } from '@ui/EmptyList';

interface Props {
  productsData: ProductsData;
  labels?: Label[];
  onLoadMore: () => void;
  hasNextPage: boolean;
  searchTerm: boolean;
  isFetchingProducts: boolean;
  isFetchingLabels: boolean;
}

export const HomeContainer = ({
  productsData,
  labels,
  onLoadMore,
  hasNextPage,
  searchTerm,
  isFetchingProducts,
  isFetchingLabels
}: Props) => {
  const dispatch = useAppDispatch();
  const { selectedLabel } = useAppSelector((state) => state.labels);
  const { containerRef: topRef, isVisible: isHeaderVisible } =
    useElementOnScreen();
  const { containerRef: bottomRef, isVisible: isFooterVisible } =
    useElementOnScreen();

  useEffect(() => {
    if (!searchTerm) {
      dispatch(labelsActions.setSelectedLabel(undefined));
    }
  }, [searchTerm]);

  const onSelectLabel = (label: Label) => {
    if (label.id === selectedLabel?.id) {
      dispatch(labelsActions.setSelectedLabel(undefined));
    } else {
      dispatch(labelsActions.setSelectedLabel(label));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const mapPagesToProducts = useCallback(
    (pages: ProductPage[]) => pages.flatMap((page) => page.products),
    [productsData]
  );

  const products = productsData ? mapPagesToProducts(productsData.pages) : [];

  return (
    <>
      <div ref={topRef}></div>
      <BannerSlider />
      <LabelSlider
        labels={labels}
        isLoading={isFetchingLabels}
        selected={selectedLabel}
        onSelectLabel={(label: Label) => onSelectLabel(label)}
      />
      <ProductListContainer>
        {isFetchingProducts && (
          <ActivityIndicator size={75} color={Color.ACCENT} />
        )}
        {!isFetchingProducts && (
          <ProductList
            productsData={productsData}
            onLoadMore={onLoadMore}
            hasNextPage={hasNextPage}
            selectedLabel={selectedLabel}
          />
        )}
        {!isFetchingProducts && products.length === 0 && <EmptyProductList />}
      </ProductListContainer>
      <div ref={bottomRef}></div>
      <ScrollToTopButtonWrapper>
        <ScrollToTopButton
          onScrollToTop={scrollToTop}
          showButton={!isHeaderVisible}
          isFooterVisible={isFooterVisible}
        />
      </ScrollToTopButtonWrapper>
    </>
  );
};

const ScrollToTopButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const ProductListContainer = styled.div`
  padding: 10px 1.5rem 5rem 1.5rem;

  @media (min-width: 768px) {
    padding: 10px 0 5rem 0;
  }
`;
