import React from 'react';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  AboutPage,
  CheckoutPage,
  ContactPage,
  DetailsPage,
  FAQPage,
  FavoritesPage,
  FeedbackPage,
  HomePage,
  PaymentErrorPage,
  PrivacyPolicyPage,
  TermsAndConditionsPage,
  ThankYouPage
} from '@pages';
import { AdminPanelPageSkeleton } from '@skeletons/AdminPanelPageSkeleton';

const AdminPages = lazy(() => import('./adminRoutes'));

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/products/:productId" element={<DetailsPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/favorites" element={<FavoritesPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditionsPage />}
      />
      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="/payment-error" element={<PaymentErrorPage />} />
      <Route path="/feedback" element={<FeedbackPage />} />

      <Route
        path="/admin-panel/*"
        element={
          <Suspense fallback={<AdminPanelPageSkeleton />}>
            <AdminPages />
          </Suspense>
        }
      />
    </Routes>
  );
};
