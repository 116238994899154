import {
  BsChevronCompactDown,
  BsStar,
  BsStarFill,
  BsStarHalf} from 'react-icons/bs';
import {
  FaArrowUp,
  FaAt,
  FaCheck,
  FaChevronCircleDown,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaClock,
  FaEdit,
  FaFacebookF,
  FaHeart,
  FaHome,
  FaInfoCircle,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaQuestionCircle,
  FaRegHeart,
  FaSearch,
  FaShoppingBag,
  FaSignOutAlt,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTiktok,
  FaTimes,
  FaYoutube} from 'react-icons/fa';
import { FcLike } from 'react-icons/fc';
import { HiOutlineMenu } from 'react-icons/hi';
import {
  MdFavorite,
  MdOutlinePermContactCalendar,
  MdPermContactCalendar,
  MdPolicy} from 'react-icons/md';
import { RiCloseFill } from 'react-icons/ri';
import { TbMoodSadDizzy } from 'react-icons/tb';

export const icons = {
  MdPolicy,
  FaInfoCircle,
  FaSearch,
  FaRegHeart,
  FaHeart,
  FaChevronLeft,
  FaChevronRight,
  RiCloseFill,
  BsStar,
  BsStarFill,
  BsStarHalf,
  FcLike,
  FaSignOutAlt,
  MdOutlinePermContactCalendar,
  MdFavorite,
  FaHome,
  FaQuestionCircle,
  BsChevronCompactDown,
  MdPermContactCalendar,
  FaEdit,
  FaCheck,
  FaArrowUp,
  HiOutlineMenu,
  FaFacebookF,
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaPhone,
  FaMapMarkerAlt,
  FaAt,
  FaShoppingBag,
  FaTimes,
  TbMoodSadDizzy,
  FaChevronCircleDown,
  FaChevronDown,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaClock
};
