import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { icons } from '@assets/icons';
import { useScreenSize } from '@hooks';
import { Label } from '@models/label';
import { ScreenSize } from '@models/screen';
import { Button, ButtonSize, ButtonType } from '@ui/Button';

const SCROLL_DISTANCE = 350;

enum ArrowButtonDirection {
  LEFT = 'left',
  RIGHT = 'right'
}

interface Props {
  labels?: Label[];
  isLoading: boolean;
  selected?: Label;
  onSelectLabel: (label: Label) => void;
}

export const LabelSlider = ({
  labels,
  isLoading,
  selected,
  onSelectLabel
}: Props) => {
  const [isStartVisible, setIsStartVisible] = useState(true);
  const [isEndVisible, setIsEndVisible] = useState(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const screenSize = useScreenSize();
  const isSmallScreen =
    screenSize !== ScreenSize.MEDIUM && screenSize !== ScreenSize.LARGE;

  const updateVisibility = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;

      setIsStartVisible(scrollLeft === 0);
      setIsEndVisible(
        Math.round(scrollLeft) + Math.round(clientWidth) >=
          Math.round(scrollWidth) - 1
      );
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;

    const resizeObserver = new ResizeObserver(() => {
      updateVisibility();
    });

    if (slider) {
      resizeObserver.observe(slider);
      slider.addEventListener('scroll', updateVisibility);
    }

    updateVisibility();

    return () => {
      if (slider) {
        resizeObserver.unobserve(slider);
        slider.removeEventListener('scroll', updateVisibility);
      }
    };
  }, []);

  const getButtonType = (label: Label): ButtonType => {
    if (!selected) {
      return ButtonType.UNSELECTED;
    }

    return label.id === selected.id
      ? ButtonType.PRIMARY
      : ButtonType.UNSELECTED;
  };

  const scrollLeft = () => {
    sliderRef.current?.scrollBy({
      left: -SCROLL_DISTANCE,
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    sliderRef.current?.scrollBy({
      left: SCROLL_DISTANCE,
      behavior: 'smooth'
    });
  };

  return (
    <Wrapper>
      {isLoading && <LoadingContainer isSmallScreen={isSmallScreen} />}

      {!isStartVisible && (
        <ArrowButtonWrapper direction={ArrowButtonDirection.LEFT}>
          <ArrowButton onClick={scrollLeft}>
            <icons.FaChevronLeft color={Color.GRAY} />
          </ArrowButton>
        </ArrowButtonWrapper>
      )}

      <Scroll ref={sliderRef}>
        {labels &&
          labels.map((label, index) => {
            return (
              <ButtonContainer key={`label-${index}`}>
                <Button
                  label={label.name}
                  type={getButtonType(label)}
                  onClick={() => onSelectLabel(label)}
                  size={isSmallScreen ? ButtonSize.SMALL : ButtonSize.MEDIUM}
                  additionalStyles={`height: 34px;`}
                />
              </ButtonContainer>
            );
          })}
      </Scroll>

      {!isEndVisible && (
        <ArrowButtonWrapper direction={ArrowButtonDirection.RIGHT}>
          <ArrowButton onClick={scrollRight}>
            <icons.FaChevronRight color={Color.GRAY} />
          </ArrowButton>
        </ArrowButtonWrapper>
      )}
    </Wrapper>
  );
};

const LoadingContainer = ({ isSmallScreen }: { isSmallScreen: boolean }) => {
  const buttons = [2, 1, 3, 2];
  const LENGTH_MULTIPLIER = isSmallScreen ? 50 : 100;

  return (
    <Scroll>
      {buttons.map((length, index) => (
        <ButtonContainer key={index}>
          <Button
            label={''}
            type={ButtonType.UNSELECTED}
            size={isSmallScreen ? ButtonSize.SMALL : ButtonSize.MEDIUM}
            additionalStyles={`
              pointer-events: none;
              width: ${length * LENGTH_MULTIPLIER}px;
              background: linear-gradient(90deg, ${
                Color.LIGHT_GRAY
              } 0%, #E9E9E9 100%);
              background-size: 300%;

              animation: loading 1.5s ease-in-out infinite;

              @keyframes loading {
                0% {
                  background-position: 0% 50%;
                }
                50% {
                  background-position: 100% 50%;
                }
                100% {
                  background-position: 0% 50%;
                }
              }
            `}
          />
        </ButtonContainer>
      ))}
    </Scroll>
  );
};

const ArrowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_GRAY};
  border-radius: 50%;
  height: 45px;
  width: 45px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    transform: scale(0.9);
  }
`;

const ArrowButtonWrapper = styled.div<{ direction: ArrowButtonDirection }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ direction }) =>
    direction === ArrowButtonDirection.RIGHT
      ? `linear-gradient(90deg, transparent 0%, ${Color.WHITE} 25%);`
      : `linear-gradient(90deg, ${Color.WHITE} 75%, transparent 100%);`};
  height: 55px;
  width: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: ${({ direction }) =>
    direction === ArrowButtonDirection.LEFT ? 0 : 10}px;
  margin-right: ${({ direction }) =>
    direction === ArrowButtonDirection.RIGHT ? 0 : 10}px;
  padding: ${({ direction }) =>
    direction === ArrowButtonDirection.LEFT ? '0 25px 0 0' : '0 0 0 25px'};
  z-index: 100;

  top: 0;
  ${({ direction }) =>
    direction === ArrowButtonDirection.LEFT ? `left: 0;` : `right: 0;`}

  @media (max-width: 450px) {
    display: none;
  }
`;

const Scroll = styled.div`
  display: flex;
  flex-wrap: none;
  overflow-x: scroll;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ButtonContainer = styled.div`
  margin: 10px 10px 10px 0;
`;

const Wrapper = styled.div`
  position: relative;
`;
