import React from 'react';

import styled from 'styled-components';

import { CartProductCard } from '@features/cart/CartProductCard';

import { CartProduct } from '../../../domain/mappers/cartProductMapper';

interface Props {
  cartItems: CartProduct[];
}

export const SummaryItemsContainer = ({ cartItems }: Props) => {
  const getNumberOfCols = () => {
    if (cartItems.length === 1) return 1;
    if (cartItems.length === 2) return 2;
    return 3;
  };

  return (
    <Wrapper cols={getNumberOfCols()}>
      {cartItems.length > 0 &&
        cartItems.map((product, index) => (
          <CartProductCard
            product={product}
            removeButton
            key={`${product.id}-${index}`}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ cols: number }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 1rem 0;

  @media (min-width: 660px) {
    grid-template-columns: repeat(
      ${({ cols }) => (cols === 3 ? 2 : cols)},
      1fr
    );
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(
      ${({ cols }) => (cols === 1 ? 2 : cols)},
      1fr
    );
  }
`;
