import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Color } from '@assets/constants';
import { icons } from '@assets/icons';
import { RemainingForFreeShipping } from '@features/checkout/RemainingForFreeShipping';
import { SummaryItemsContainer } from '@features/summary/SummaryItemsContainer';
import { useScreenSize } from '@hooks';
import { PromoCode } from '@models/promoCode';
import { ScreenSize } from '@models/screen';
import { ShippingData } from '@models/shipping';
import { useCheckPromoCode } from '@mutations';
import { ActivityIndicator } from '@ui/ActivityIndicator';
import { Button } from '@ui/Button';
import { Divider } from '@ui/Divider';
import { Input } from '@ui/Input';

import { CartProduct } from '../../domain/mappers/cartProductMapper';

interface Props {
  cartItems: CartProduct[];
  totalPrice: number;
  discountedTotalPrice?: number;
  shipping: ShippingData;
  enteredPromoCode: string;
  setEnteredPromoCode: (promoCode: string) => void;
  isPromoCodeValid: boolean | null;
  setIsPromoCodeValid: (isValid: boolean | null) => void;
  onApplyPromoCode: (promoCode: PromoCode | null) => void;
  onContinue: () => void;
  isLoading: boolean;
}

export const SummaryContainer = ({
  cartItems,
  totalPrice,
  discountedTotalPrice,
  shipping,
  enteredPromoCode,
  setEnteredPromoCode,
  isPromoCodeValid,
  setIsPromoCodeValid,
  onApplyPromoCode,
  onContinue,
  isLoading
}: Props) => {
  const { mutateAsync: checkPromoCode, isPending: isCheckingPromoCode } =
    useCheckPromoCode();
  const screenSize = useScreenSize();

  const isSmallScreen = screenSize === ScreenSize.SMALL;
  const isFreeShipping = totalPrice >= shipping.minimumAmount;

  const checkPromoCodeValidity = async () => {
    const code = await checkPromoCode(enteredPromoCode);
    setIsPromoCodeValid(!!code);
    onApplyPromoCode(code ?? null);
  };

  const getFinalPrice = (): number => {
    let finalPrice: number;

    if (discountedTotalPrice) {
      finalPrice = isFreeShipping
        ? discountedTotalPrice
        : discountedTotalPrice + shipping.shippingCost;
    } else {
      finalPrice = isFreeShipping
        ? totalPrice
        : totalPrice + shipping.shippingCost;
    }

    return Number(finalPrice.toFixed(2));
  };

  return (
    <SummaryWrapper>
      {cartItems.length === 0 && (
        <BlurOverlay>
          <EmptyCheckoutContainer>
            <Row center>
              <Text>Количката е празна</Text>
              <BrokenHeartIcon
                src={require('../../assets/images/broken-heart-icon.png')}
              />
            </Row>
            <StyledLink to={'/'}>Разгледай тениски</StyledLink>
          </EmptyCheckoutContainer>
        </BlurOverlay>
      )}
      <LargeText>Обобщение</LargeText>
      {isLoading ? (
        <LoadingWrapper>
          <ActivityIndicator size={100} color={Color.ACCENT} />
        </LoadingWrapper>
      ) : (
        <>
          <SummaryItemsContainer cartItems={cartItems} />
          {!isFreeShipping && (
            <RemainingForFreeShipping
              totalPrice={totalPrice}
              minimumAmount={shipping.minimumAmount}
            />
          )}
        </>
      )}
      <Divider additionalStyles={'margin: 20px 0'} />
      <PromoCodeWrapper>
        <LargeText>Промо код</LargeText>
        <InputWrapper>
          <Input
            value={enteredPromoCode}
            placeholder={'PROMOCODE'}
            onChange={(e) => setEnteredPromoCode(e.target.value.toUpperCase())}
            onEnterKey={checkPromoCodeValidity}
            centered
            additionalStyles={`
              font-size: 18px;
              padding: 8px;
              position: static;
            `}
          />
          <PromoCodeButton
            isValid={isPromoCodeValid}
            isLoading={isCheckingPromoCode}
            onClick={checkPromoCodeValidity}
          >
            {isCheckingPromoCode && (
              <ActivityIndicator size={20} color={Color.DARK_GRAY} />
            )}
            {isPromoCodeValid === null && !isCheckingPromoCode && (
              <Text>Приложи промо код</Text>
            )}
            {isPromoCodeValid === false && !isCheckingPromoCode && (
              <>
                <Text>Промо кодът не е валиден</Text>
                <icons.FaTimes size={20} color={Color.WHITE} />
              </>
            )}
            {isPromoCodeValid === true && !isCheckingPromoCode && (
              <>
                <Text>Промо кодът е приложен</Text>
                <icons.FaCheck size={20} color={Color.WHITE} />
              </>
            )}
          </PromoCodeButton>
        </InputWrapper>
      </PromoCodeWrapper>
      <Divider additionalStyles={'margin: 20px 0'} />
      <PriceAndCtaWrapper isSmallScreen={isSmallScreen}>
        <PriceWrapper>
          <Row>
            <Text>Тениски:</Text>
            <Row>
              {discountedTotalPrice ? (
                <>
                  <DiscountedPrice>
                    {totalPrice.toFixed(2)}
                    лв
                  </DiscountedPrice>
                  <MediumText style={{ color: 'red' }}>
                    {discountedTotalPrice.toFixed(2)}лв
                  </MediumText>
                </>
              ) : (
                <MediumText>{totalPrice.toFixed(2)}лв</MediumText>
              )}
            </Row>
          </Row>
          <Row>
            <Text>Доставка:</Text>
            <Row>
              {isFreeShipping ? (
                <>
                  <DiscountedPrice>{shipping.shippingCost}лв</DiscountedPrice>
                  <MediumText style={{ color: 'red' }}>Безплатно</MediumText>
                </>
              ) : (
                <MediumText>{shipping.shippingCost}лв</MediumText>
              )}
            </Row>
          </Row>
          <Row>
            <Text>Общо:</Text>
            <Row>
              {isPromoCodeValid && (
                <DiscountedPrice>
                  {(isFreeShipping
                    ? totalPrice
                    : totalPrice + shipping.shippingCost
                  ).toFixed(2)}
                  лв
                </DiscountedPrice>
              )}
              <Price discounted={!!isPromoCodeValid}>
                {getFinalPrice()}
                лв
              </Price>
            </Row>
          </Row>
        </PriceWrapper>
        {isSmallScreen && <Divider additionalStyles={'margin: 20px 0'} />}
        <Button label={'Продължи'} onClick={onContinue} />
      </PriceAndCtaWrapper>
    </SummaryWrapper>
  );
};

const StyledLink = styled(Link)`
  color: ${Color.ACCENT};
`;

const BrokenHeartIcon = styled.img`
  width: 24px;
`;

const EmptyCheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${Color.WHITE};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

  @media (min-width: 1366px) {
    font-size: 1.5rem;
  }
`;

const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const PromoCodeButton = styled.div<{
  isValid: boolean | null;
  isLoading: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  gap: 5px;
  padding: 0.3rem;
  background-color: ${({ isValid, isLoading }) => {
    if (isLoading) return Color.LIGHT_GRAY;
    if (isValid === null) return Color.LIGHT_GRAY;
    if (isValid === true) return Color.GREEN_CHECK;
    if (isValid === false) return Color.LIGHT_RED;
  }};
  color: ${({ isValid }) => (isValid === null ? Color.DARK_GRAY : 'white')};
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
`;

const MediumText = styled.p`
  font-size: 20px;
  font-weight: 500;
`;

const Row = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ center }) => (center ? 'center' : 'baseline')};
  gap: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  @media (min-width: 1366px) {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const PriceAndCtaWrapper = styled.div<{ isSmallScreen: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  ${({ isSmallScreen }) =>
    isSmallScreen &&
    `
      grid-template-columns: 1fr;
      gap: 0;
    `}
`;

const DiscountedPrice = styled.p`
  font-size: 16px;
  text-decoration: line-through;
`;

const Price = styled.p<{ discounted: boolean }>`
  font-size: 30px;
  font-weight: 600;
  color: ${({ discounted }) => (discounted ? Color.RED : Color.BLACK)};
`;

const PromoCodeWrapper = styled.div`
  /* margin-top: 20px; */
`;

const LargeText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${Color.DARK_GRAY};
`;

const SummaryWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
