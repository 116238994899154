import React, { useEffect, useState } from 'react';

import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

import { AdminPanelAuth } from '@containers/adminPanel/AdminPanelAuth';
import { OrderDetailsContainer } from '@containers/adminPanel/OrderDetailsContainer';

const OrderDetailsPage = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => setUser(user));

    return () => unsubscribe();
  }, []);

  return user !== null ? <OrderDetailsContainer /> : <AdminPanelAuth />;
};

export default OrderDetailsPage;
