import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

// not currently used
export const useSpeedyOffices = () => {
  const listOffices = async () => {
    const url = `${REACT_APP_TENISKI_API_BASE_URL}/speedy/listOffices`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    }
  };

  return useMutation({
    mutationFn: listOffices
  });
};
