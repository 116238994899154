import { Product, ProductType } from '@models/product';

import {
  CartProduct,
  CartTShirtProduct
} from '../domain/mappers/cartProductMapper';
import { LocalItem } from './cartSlice';

export const getLocalCartItems = (): LocalItem[] =>
  JSON.parse(localStorage.getItem('cartItems') ?? '[]');

export const getLocalFavorites = (): Product[] =>
  JSON.parse(localStorage.getItem('favorites') ?? '[]');

export const cartItemExists = (
  item: CartProduct | LocalItem,
  payload: CartProduct
): boolean => {
  if (payload.productType === ProductType.MUG) {
    return (
      item.id === payload.id &&
      item.customizationNotes === payload.customizationNotes
    );
  }

  const tShirtItem = item as CartTShirtProduct;

  return (
    tShirtItem.id === payload.id &&
    tShirtItem.size === payload.size &&
    tShirtItem.color === payload.color &&
    tShirtItem.customizationNotes === payload.customizationNotes
  );
};
