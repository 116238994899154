import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

export const useSpeedyOfficesByCity = () => {
  const findOfficesByCity = async (cityName: string) => {
    const url = `${REACT_APP_TENISKI_API_BASE_URL}/speedy/findOfficesByCity`;

    try {
      const response = await fetch(url, {
        headers: {
          cityname: cityName
        }
      });
      const data = await response.json();

      return data;
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    }
  };

  return useMutation({
    mutationFn: (cityName: string) => findOfficesByCity(cityName)
  });
};
