import { AppConfig } from '@models/appConfig';
import { createSlice } from '@reduxjs/toolkit';

const initialState: AppConfig = {
  isInMaintenanceMode: false,
  transactions: {
    areInTestMode: false,
    areDisabled: false
  }
};

export const configSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setConfig: (_, { payload }) => {
      return payload;
    }
  }
});

export const configActions = configSlice.actions;
