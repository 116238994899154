import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import styled from 'styled-components';
import { v4 as uuid4 } from 'uuid';

import { Color } from '@assets/constants';
import {
  Mug,
  ProductType,
  TShirt,
  TShirtSize,
  TShirtSizes,
  TShirtThumbnail,
  TShirtType
} from '@models/product';
import { useCreateThumbnail, useIndexProduct } from '@mutations';
import { useLabels } from '@queries';
import { Button } from '@ui/Button';
import { Checkbox } from '@ui/Checkbox';
import { ColorTile } from '@ui/ColorTile';
import { EdittableAndSelectableItems } from '@ui/EdittableAndSelectableItems';
import { ImageInput } from '@ui/ImageInput';
import { Input } from '@ui/Input';
import Switch, { SwitchSize } from '@ui/Switch';

import { db, storage } from '../../firebase/firebaseConfig';
import {
  ColorImages,
  defaultImageDetails,
  defaultImagesObj,
  defaultSizesObj,
  generateCreatedAt,
  ImageDetails,
  mapSizeToString,
  selectLabelIds,
  SizesCheckbox,
  supportedImageTypes,
  TShirtColor
} from './utils';

export const NewProductContainer = () => {
  const [productType, setProductType] = useState<ProductType>(
    ProductType.TSHIRT
  );
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number | string>('');
  const [thumbnailImage, setThumbnailImage] = useState<string | null>(null);
  const [images, setImages] = useState<ColorImages>(defaultImagesObj);
  const [mugImage, setMugImage] = useState<File | null>(null);
  const [sizes, setSizes] = useState<SizesCheckbox>(defaultSizesObj.all);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const [isMock, setIsMock] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: labels, isPending: isFetchingLabels } = useLabels();
  const { mutate: indexProduct } = useIndexProduct();
  const { mutateAsync: createThumbnail } = useCreateThumbnail();

  const imageForTypeIsSelected = (type: TShirtType) =>
    Object.values(images[type]).some((image) => image);

  const noImagesAreSelected = () => {
    if (productType === ProductType.MUG) return mugImage === null;

    return (
      Object.values(images.men).every((image) => image === null) &&
      Object.values(images.women).every((image) => image === null) &&
      Object.values(images.kids).every((image) => image === null)
    );
  };

  const noThumbnailSelected =
    productType === ProductType.TSHIRT && !thumbnailImage;

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setPrice(0);
    setImages(defaultImagesObj);
    setThumbnailImage(null);
    setSizes(defaultSizesObj.all);
  };

  const handleSelectTShirtImage = (
    files: FileList | null,
    color: string,
    colors: keyof ColorImages
  ) => {
    if (files) {
      setImages({
        ...images,
        [colors]: {
          ...images[colors],
          [color]: files[0]
        }
      });
    }
  };

  const handleSelectMugImage = (files: FileList | null) =>
    setMugImage(files ? files[0] : null);

  const handleSelectSize = (size: TShirtSize, sizeType: TShirtType) => {
    const newSizes = {
      ...sizes,
      [sizeType]: {
        ...sizes[sizeType],
        [size]:
          // @ts-ignore
          !sizes[sizeType][size]
      }
    };
    setSizes(newSizes);
  };

  const createProduct = async () => {
    const productId = uuid4();
    const { createdAt, createdAtTimestamp } = generateCreatedAt(Date.now());

    if (productType === ProductType.TSHIRT) {
      if (noImagesAreSelected() || !thumbnailImage) {
        return;
      }

      let imageDetails: ImageDetails = JSON.parse(
        JSON.stringify(defaultImageDetails)
      );
      const thumbnailImageDetails: TShirtThumbnail = {
        name: '',
        url: '',
        color: TShirtColor.WHITE,
        type: TShirtType.MEN
      };
      for (const [colorType, colors] of Object.entries(images)) {
        for await (const [color, image] of Object.entries(colors)) {
          if (image) {
            const storageRef = ref(
              storage,
              `images/${productId}/${title}-${colorType}-${color}`
            );
            const snapshot = await uploadBytes(storageRef, image as File);
            const imageUrl = await getDownloadURL(snapshot.ref);

            const imageName = (image as File).name;

            imageDetails = {
              ...imageDetails,
              [colorType as keyof ImageDetails]: {
                ...imageDetails[colorType as keyof ImageDetails],
                [color]: {
                  name: imageName,
                  url: imageUrl
                }
              }
            };

            if (thumbnailImage === imageName) {
              const { thumbnailUrl } = await createThumbnail({
                imageUrl,
                productId
              });

              thumbnailImageDetails.name = imageName;
              thumbnailImageDetails.url = thumbnailUrl;
              thumbnailImageDetails.color = color as TShirtColor;
              thumbnailImageDetails.type = colorType as TShirtType;
            }
          }
        }
      }

      const sizesObj: TShirtSizes = {
        men: [],
        women: [],
        kids: [],
        oversized: []
      };
      for (const [sizeType, sizesArray] of Object.entries(sizes)) {
        const imageSelected = imageForTypeIsSelected(sizeType as TShirtType);
        if (!imageSelected) {
          continue;
        }

        for (const [size, selected] of Object.entries(sizesArray)) {
          if (selected) {
            sizesObj[sizeType as TShirtType].push(size as TShirtSize);
          }
        }
      }

      const product: TShirt = {
        id: productId,
        createdAt,
        createdAtTimestamp,
        title,
        description,
        price: Number(price),
        thumbnail: thumbnailImageDetails,
        images: imageDetails,
        sizes: sizesObj,
        labels: selectedLabelIds,
        type: ProductType.TSHIRT,
        views: 0,
        mock: isMock
      };

      await setDoc(doc(db, 'products', productId), product);

      return { ...product, id: productId };
    }

    const storageRef = ref(storage, `images/${productId}/${title}`);
    const snapshot = await uploadBytes(storageRef, mugImage as File);
    const imageUrl = await getDownloadURL(snapshot.ref);
    const imageName = (mugImage as File).name;
    const { thumbnailUrl } = await createThumbnail({
      imageUrl,
      productId
    });

    const product: Mug = {
      id: productId,
      createdAt,
      createdAtTimestamp,
      title,
      description,
      price: Number(price),
      thumbnail: {
        name: imageName,
        url: thumbnailUrl
      },
      image: {
        name: imageName,
        url: imageUrl
      },
      labels: selectedLabelIds,
      type: ProductType.MUG,
      views: 0,
      mock: isMock
    };

    await setDoc(doc(db, 'products', productId), product);

    return { ...product, id: productId };
  };

  const addNewProduct = async () => {
    if (!title) {
      return toast.error('💥 Please add a title for your product!');
    }
    if (!description) {
      return toast.error('💥 Please add a description for your product.');
    }
    if (!price) {
      return toast.error('💥 Please add a price for your product.');
    }
    if (noImagesAreSelected()) {
      return toast.error('💥 Please add at least one image for your product.');
    }
    if (noThumbnailSelected) {
      return toast.error('💥 Please add a thumbnail image for your product.');
    }
    const hasSize = Object.values(sizes).filter((selected) => selected).length;
    if (!hasSize) {
      return toast.error(
        '💥 Please choose at least one size for your product.'
      );
    }

    setIsLoading(true);
    try {
      const product = await createProduct();

      if (product) {
        indexProduct(product);
      }

      resetForm();
      return toast.success('Product added successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return toast.error(`💥 ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <TitleRow>
        <Title>Add new product</Title>

        <ProductTypeSwitchWrapper>
          <Text
            color={
              productType === ProductType.MUG ? Color.DARK_GRAY : Color.WHITE
            }
          >
            T-Shirt
          </Text>
          <Switch
            isOn={productType === ProductType.MUG}
            handleToggle={() =>
              setProductType(
                productType === ProductType.TSHIRT
                  ? ProductType.MUG
                  : ProductType.TSHIRT
              )
            }
            id={'productTypeSwitch'}
            size={SwitchSize.SMALL}
          />
          <Text
            color={
              productType === ProductType.TSHIRT ? Color.DARK_GRAY : Color.WHITE
            }
          >
            Mug
          </Text>
        </ProductTypeSwitchWrapper>
      </TitleRow>
      <InputContainer>
        <Text>Title</Text>
        <Input
          value={title}
          placeholder={'Title...'}
          onChange={(e) => setTitle(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <Text>Description</Text>
        <Input
          value={description}
          placeholder={'Description...'}
          onChange={(e) => setDescription(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <Text>Price</Text>
        <Input
          value={price}
          placeholder={'Price...'}
          type={'number'}
          onChange={(e) => setPrice(e.target.value)}
        />
      </InputContainer>

      {productType === ProductType.TSHIRT && (
        <>
          <Text>Thumbnail Image</Text>
          <SizesWrapper>
            <ImageInputContainer>
              <SmallText>Thumbnail:</SmallText>
              <ThumbnailImage thumbnail={thumbnailImage}>
                {thumbnailImage ?? 'N/A'}
              </ThumbnailImage>
            </ImageInputContainer>
          </SizesWrapper>
          <Text>Images</Text>
          <SizesWrapper>
            <ImageInputWrapper>
              {Object.keys(images).map((colors, index) => (
                <ColorTypeContainer key={`color-type-${index}`}>
                  <SmallText key={`color-${index}`}>
                    {colors.toUpperCase()}:
                  </SmallText>
                  {Object.keys(images[colors as keyof ColorImages]).map(
                    (color, index) => (
                      <ImageInputContainer key={`image-input-${index}`}>
                        <ColorTile color={color} />
                        <ImageInput
                          fileName={
                            // @ts-ignore
                            images[colors as keyof ColorImages][color]?.name
                          }
                          supportedTypes={supportedImageTypes}
                          onChange={(e) =>
                            handleSelectTShirtImage(
                              e.target.files,
                              color,
                              colors as keyof ColorImages
                            )
                          }
                          onMakeThumbnail={(fileName) =>
                            setThumbnailImage(fileName)
                          }
                          thumbnailSelected={
                            thumbnailImage ===
                            // @ts-ignore
                            images[colors as keyof ColorImages][color]?.name
                          }
                        />
                      </ImageInputContainer>
                    )
                  )}
                </ColorTypeContainer>
              ))}
            </ImageInputWrapper>
          </SizesWrapper>
          <Text>T-Shirt Sizes</Text>
          <SizesWrapper>
            {Object.keys(sizes).map((sizeType, index) => {
              const showSizesForType = imageForTypeIsSelected(
                sizeType as TShirtType
              );

              return (
                <SizesRow
                  disabled={!showSizesForType}
                  key={`size-row-${index}`}
                >
                  <SmallText key={`size-${index}`}>
                    {sizeType.toUpperCase()}:
                  </SmallText>
                  <InputContainer key={`size-input-${index}`}>
                    <SizesContainer>
                      {Object.keys(sizes[sizeType as TShirtType]).map(
                        (size, index) => {
                          const checked =
                            // @ts-ignore
                            sizes[sizeType][size];

                          return (
                            <CheckboxContainer key={`checkbox-${index}`}>
                              <Checkbox
                                label={mapSizeToString(size as TShirtSize)}
                                checked={checked}
                                onClick={() =>
                                  handleSelectSize(
                                    size as TShirtSize,
                                    sizeType as TShirtType
                                  )
                                }
                              />
                            </CheckboxContainer>
                          );
                        }
                      )}
                    </SizesContainer>
                  </InputContainer>
                </SizesRow>
              );
            })}
          </SizesWrapper>
        </>
      )}

      {productType === ProductType.MUG && (
        <>
          <Text>Image</Text>
          <MugImageInputWrapper>
            <ImageInput
              fileName={mugImage?.name}
              supportedTypes={supportedImageTypes}
              onChange={(e) => handleSelectMugImage(e.target.files)}
            />
          </MugImageInputWrapper>
        </>
      )}

      <InputContainer>
        <Text>Labels</Text>
        <EdittableAndSelectableItems
          items={labels}
          selectedItemIds={selectedLabelIds}
          handleSelectItem={(labelId) =>
            selectLabelIds(labelId, selectedLabelIds, setSelectedLabelIds)
          }
          isFetchingItems={isFetchingLabels}
          selective
        />
      </InputContainer>

      <MockSwitchWrapper>
        <Text>Mock Product</Text>
        <Switch
          isOn={isMock}
          handleToggle={() => setIsMock((prev) => !prev)}
          id={'mockSwitch'}
          size={SwitchSize.SMALL}
        />
      </MockSwitchWrapper>

      <ButtonContainer>
        <Button
          label={'Add new product'}
          loading={isLoading}
          onClick={addNewProduct}
        />
      </ButtonContainer>
    </Wrapper>
  );
};

const ProductTypeSwitchWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const MockSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ThumbnailImage = styled.div<{ thumbnail: string | null }>`
  border: none;
  padding: 8px 16px;
  background-color: ${(props) =>
    props.thumbnail ? Color.ACCENT : Color.LIGHT_GRAY};
  border-radius: 2rem;
  color: ${(props) => (props.thumbnail ? Color.BLACK : Color.GRAY)};
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

const SizesRow = styled.div<{ disabled: boolean }>`
  ${(props) =>
    props.disabled &&
    `
      pointer-events: none;
      opacity: 0.25;
      filter: blur(2px);
  `}
`;

const ColorTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${Color.GRAY};
  padding-bottom: 10px;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const SizesWrapper = styled.div`
  margin: 10px 0 10px 0;
  padding: 10px;
  background-color: ${Color.DARK_GRAY};
  border-radius: 10px;
  overflow-y: scroll;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const SizesContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const SmallText = styled.p`
  color: ${Color.WHITE};
`;

const ImageInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MugImageInputWrapper = styled.div`
  margin-top: 10px;
`;

const ImageInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

const Text = styled.p<{ color?: Color }>`
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  color: ${({ color }) => color ?? Color.WHITE};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0 10px 0;
`;

const Title = styled.p`
  font-size: 24px;
  color: ${Color.WHITE};
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;
