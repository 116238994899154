import { toast } from 'react-toastify';

import { collection, getDocs } from 'firebase/firestore';

import { Label } from '@models/label';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const useLabels = () => {
  const getLabels = async () => {
    const labelsCollectionRef = collection(db, 'labels');

    try {
      const data = await getDocs(labelsCollectionRef);
      const labels = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      })) as Label[];

      labels.sort((a, b) => a.index - b.index); // sort by index
      return labels;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(`💥 ${e.message}`);
      return [];
    }
  };

  return useQuery({
    queryKey: ['labels'],
    queryFn: getLabels,
    initialData: []
  });
};
