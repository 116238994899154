import { CartProduct } from 'domain/mappers/cartProductMapper';

import { OrderShippingInfo } from '@models/order';
import { PromoCode } from '@models/promoCode';
import { useMutation } from '@tanstack/react-query';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

interface CreateOrderArgs {
  orderId: string;
  items: CartProduct[];
  orderShippingInfo: OrderShippingInfo & {
    shippingCost: number;
    minimumAmount: number;
  };
  promoCode: PromoCode | null;
}

export const useCreateOrder = () => {
  const createOrder = async ({
    orderId,
    items,
    orderShippingInfo,
    promoCode
  }: CreateOrderArgs) => {
    try {
      const res = await fetch(
        `${REACT_APP_TENISKI_API_BASE_URL}/orders/createOrder`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ orderId, items, orderShippingInfo, promoCode })
        }
      );
      const { orderNumber } = await res.json();

      return orderNumber;
    } catch (e: unknown) {
      console.log(`Error adding items to order: ${(e as Error).message}`);
    }
  };

  return useMutation({
    mutationFn: (args: CreateOrderArgs) => createOrder(args)
  });
};
