import { PromoCode } from '@models/promoCode';

import { CartProduct } from '../mappers/cartProductMapper';

export interface MyPosSuccessData {
  OrderID: string;
  RequestSTAN: string;
  RequestDateTime: string;
  IPC_Trnref: number;
}

interface MyPosData extends MyPosSuccessData {
  Amount: string;
  Currency: string;
  BillingDescriptor: string;
  SID: string;
}

export enum OrderStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  CANCELLED = 'CANCELLED'
}

export interface Order {
  id: string;
  orderNumber: string;
  status: OrderStatus;
  createdAt: string;
  shippingInfo: OrderShippingInfo & {
    shippingCost: number;
    minimumAmount: number;
  };
  items: CartProduct[];
  promoCode: PromoCode | null;
  myPosData: MyPosData;
  haveItemsBeenAdded?: boolean;
  isAdminEmailSent?: boolean;
  isCustomerOrderCreatedEmailSent?: boolean;
  isCustomerOrderSentEmailSent?: boolean;
}

export interface OrderShippingInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  personalAddress?: string;
  speedyOffice?: string;
}
