import { toast } from 'react-toastify';

import { doc, getDoc } from 'firebase/firestore';

import { Order } from '@models/order';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const useOrderById = (orderId: string) => {
  const getOrderById = async () => {
    const docRef = doc(db, 'orders', orderId);

    try {
      const orderDoc = await getDoc(docRef);

      if (orderDoc.exists()) {
        const order = { ...orderDoc.data(), id: orderDoc.id } as Order;

        return order;
      }
    } catch (e: unknown) {
      toast.error(`Error getting order: ${(e as Error).message}`);
    }
  };

  return useQuery({
    queryKey: ['order', orderId],
    queryFn: getOrderById
  });
};
