import { toast } from 'react-toastify';

import { collection, getDocs } from 'firebase/firestore';

import { Order } from '@models/order';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const useOrders = () => {
  const getOrders = async () => {
    const ordersCollectionRef = collection(db, 'orders');

    try {
      const data = await getDocs(ordersCollectionRef);
      const orders = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      })) as Order[];

      return orders;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(`💥 ${e.message}`);
      return [];
    }
  };

  return useQuery({
    queryKey: ['orders'],
    queryFn: getOrders,
    initialData: []
  });
};
