import { toast } from 'react-toastify';

import { collection, getDocs } from 'firebase/firestore';

import { Discount } from '@models/discount';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const useDiscounts = () => {
  const discountsCollectionRef = collection(db, 'discounts');

  const getDiscounts = async () => {
    try {
      const data = await getDocs(discountsCollectionRef);
      const discounts = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      })) as Discount[];

      return discounts;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(`💥 ${e.message}`);
      return [];
    }
  };

  return useQuery({
    queryKey: ['discounts'],
    queryFn: getDiscounts,
    initialData: [],
    staleTime: 1000 * 60 * 60, // 1 hour
    initialDataUpdatedAt: 0
  });
};
