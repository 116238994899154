import React from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { MenuLinks } from '@features/menu/MenuLinks';
import { MenuLogo } from '@features/menu/MenuLogo';
import { useCustomNavigate, useModalClose, useScreenSize } from '@hooks';
import { ScreenSize } from '@models/screen';
import { Modal, ModalEnterAnimation, ModalExitAnimation } from '@ui/Modal';

interface Props {
  showMenu: boolean;
  onCloseMenu: () => void;
}

export const MenuMobile = ({ showMenu, onCloseMenu }: Props) => {
  const { closing, handleClose } = useModalClose(onCloseMenu);
  const navigate = useCustomNavigate();
  const location = useLocation();
  const screenSize = useScreenSize();

  const currentPage = location.pathname;

  const isSmallScreen = screenSize === ScreenSize.SMALL;

  return (
    <>
      {showMenu && (
        <Modal
          onClose={handleClose}
          enterAnimation={ModalEnterAnimation.SLIDE_RIGHT}
          exitAnimation={ModalExitAnimation.SLIDE_LEFT}
          backButton
          closing={closing}
          additionalStyles={`
            width: ${isSmallScreen ? '65%' : '35%'};
            height: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          `}
        >
          <LogoWrapper>
            <MenuLogo onClick={() => navigate('/')} />
          </LogoWrapper>
          <MenuContainer>
            <MenuLinks
              currentPage={currentPage}
              onCloseMenu={handleClose}
              isSmallScreen={isSmallScreen}
            />
          </MenuContainer>
        </Modal>
      )}
    </>
  );
};

const LogoWrapper = styled.div`
  position: relative;
  height: 50px;
`;

const MenuContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  overflow-y: auto;
  max-height: calc(100vh - 160px);
`;
