import { doc, getDoc } from 'firebase/firestore';

import { Product } from '@models/product';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const useProductById = (productId: string) => {
  const getProductById = async (): Promise<Product | undefined> => {
    try {
      const docRef = doc(db, 'products', productId);
      const productDoc = await getDoc(docRef);

      if (productDoc.exists()) {
        const product = { ...productDoc.data(), id: productDoc.id } as Product;

        return product;
      }
    } catch (error) {
      throw new Error('Error getting product: ', error as unknown as Error);
    }
  };

  return useQuery({
    queryKey: ['product', productId],
    queryFn: getProductById
  });
};
