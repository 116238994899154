import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { doc, getDoc } from 'firebase/firestore';

import { useAppDispatch } from '@hooks';
import { AppConfig } from '@models/appConfig';
import { configActions } from '@store';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

const fetchAppConfig = async (): Promise<AppConfig> => {
  const maintenanceRef = doc(db, 'config', 'maintenance');
  const transactionsConfigRef = doc(db, 'config', 'transactions');

  const [maintenanceConfig, transactionsConfig] = await Promise.all([
    getDoc(maintenanceRef),
    getDoc(transactionsConfigRef)
  ]);

  if (!maintenanceConfig.exists()) {
    throw new Error('Maintenance config not found');
  }

  if (!transactionsConfig.exists()) {
    throw new Error('Transaction config not found');
  }

  return {
    isInMaintenanceMode: Boolean(maintenanceConfig.data().isOn),
    transactions: {
      areInTestMode: Boolean(transactionsConfig.data().testMode),
      areDisabled: Boolean(transactionsConfig.data().disabled)
    }
  };
};

export const useAppConfig = () => {
  const dispatch = useAppDispatch();

  const query = useQuery({
    queryKey: ['appConfig'],
    queryFn: fetchAppConfig,
    staleTime: 1000 * 60 * 5 // 5 minutes
  });

  useEffect(() => {
    if (query.isSuccess && query.data) {
      dispatch(configActions.setConfig(query.data));
    }
    if (query.isError) {
      toast.error(`💥 ${query.error.message}`);
    }
  }, [query.isSuccess, query.data, query.isError, query.error, dispatch]);

  return { isLoading: query.isLoading, error: query.error };
};
