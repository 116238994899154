import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { doc, updateDoc } from 'firebase/firestore';
import styled from 'styled-components';

import { Color } from '@assets/constants';
import { icons } from '@assets/icons';
import { ReactComponent as Logo } from '@assets/images/logo-horizontal.svg';
import { CartProductCard } from '@features/cart/CartProductCard';
import { OrderDetailsTable } from '@features/tables/OrderDetailsTable';
import { useCustomNavigate, useModalClose } from '@hooks';
import { OrderStatus } from '@models/order';
import { useOrderEmail } from '@mutations';
import { useOrderById, useShipping } from '@queries';
import { ActivityIndicator } from '@ui/ActivityIndicator';
import { Button, ButtonSize } from '@ui/Button';
import { Modal, ModalEnterAnimation, ModalExitAnimation } from '@ui/Modal';
import Switch, { SwitchSize } from '@ui/Switch';
import { sendOrderSentEmailToCustomer } from '@utils/emailUtils';

import { db } from '../../firebase/firebaseConfig';

export const OrderDetailsContainer = () => {
  const [orderStatus, setOrderStatus] = useState<OrderStatus | null>(null);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [showSendEmailModal, setShowSendEmailModal] = useState<boolean>(false);

  const { closing, handleClose } = useModalClose(() =>
    setShowSendEmailModal(false)
  );
  const navigate = useCustomNavigate();
  const { orderId } = useParams();

  const { data: order, isPending: isFetchingOrder } = useOrderById(
    orderId ?? ''
  );
  const { data: shipping } = useShipping();
  const { mutateAsync: sendOrderEmail } = useOrderEmail();

  const updateStatus = async (orderId: string, newStatus: OrderStatus) => {
    await updateDoc(doc(db, 'orders', orderId), { status: newStatus });
    setOrderStatus(newStatus);
  };

  const toggleOrderStatus = useCallback(async () => {
    setIsUpdatingStatus(true);

    try {
      if (!order) {
        throw new Error('Order not found');
      }

      if (orderStatus === OrderStatus.PENDING) {
        !order.isCustomerOrderSentEmailSent
          ? setShowSendEmailModal(true)
          : await updateStatus(order.id, OrderStatus.SENT);
      } else {
        await updateStatus(order.id, OrderStatus.PENDING);
      }
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    } finally {
      setIsUpdatingStatus(false);
    }
  }, [order, orderStatus]);

  const toggleSentStatus = useCallback(async () => {
    setIsUpdatingStatus(true);

    try {
      if (!order) {
        throw new Error('Order not found');
      }

      const totalPrice = order.items.reduce((acc, item) => acc + item.price, 0);

      setShowSendEmailModal(false);
      await updateStatus(order.id, OrderStatus.SENT);
      sendOrderSentEmailToCustomer(
        sendOrderEmail,
        order.myPosData,
        order.orderNumber,
        order.shippingInfo,
        order.items,
        order.promoCode,
        totalPrice,
        shipping
      );
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    } finally {
      setIsUpdatingStatus(false);
    }
  }, [order, orderStatus]);

  const toggleCancelOrder = useCallback(async () => {
    setIsUpdatingStatus(true);

    try {
      if (!order) {
        throw new Error('Order not found');
      }

      const newStatus =
        orderStatus === OrderStatus.CANCELLED
          ? OrderStatus.PENDING
          : OrderStatus.CANCELLED;

      await updateStatus(order.id, newStatus);
    } catch (e: unknown) {
      toast.error(`💥 ${(e as Error).message}`);
    } finally {
      setIsUpdatingStatus(false);
    }
  }, [order, orderStatus]);

  return (
    <Wrapper>
      {showSendEmailModal && (
        <Modal
          onClose={handleClose}
          enterAnimation={ModalEnterAnimation.SLIDE_DOWN_CENTER}
          exitAnimation={ModalExitAnimation.SLIDE_RIGHT_CENTER}
          closing={closing}
        >
          <SendEmailModal>
            <Text>
              Setting the status of this order to SENT will send an email to the
              customer, the email will be sent only once! Are you sure?
            </Text>
            <ModalButtonsWrapper>
              <Button
                label={'Yes, send email'}
                onClick={toggleSentStatus}
                additionalStyles={`width: 100%;`}
              />
              <Button
                label={'No, cancel'}
                backgroundColor={Color.LIGHT_RED}
                onClick={() => setShowSendEmailModal(false)}
                additionalStyles={`width: 100%;`}
              />
            </ModalButtonsWrapper>
          </SendEmailModal>
        </Modal>
      )}
      <Header>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </Header>

      <OrderWrapper>
        <BackButton onClick={() => navigate('/admin-panel/orders')}>
          <icons.FaChevronLeft />
          <p>Back to Orders</p>
        </BackButton>
        <OrderContainer>
          {isFetchingOrder && (
            <ActivityIndicatorWrapper>
              <ActivityIndicator size={100} color={Color.ACCENT} />
            </ActivityIndicatorWrapper>
          )}
          {!isFetchingOrder && order && (
            <>
              <TitleWrapper>
                <Title>{order.createdAt}</Title>
                <Title>Order Number: {order.orderNumber}</Title>
              </TitleWrapper>

              <StatusSwitchWrapper>
                <Row>
                  <StatusIcon active={orderStatus === OrderStatus.PENDING}>
                    {orderStatus === OrderStatus.PENDING && (
                      <icons.FaClock color={'#EDD377'} size={16} />
                    )}
                  </StatusIcon>

                  <StatusText active={orderStatus === OrderStatus.PENDING}>
                    Pending
                  </StatusText>
                </Row>

                {isUpdatingStatus && (
                  <BlurryWrapper loading={true}>
                    <ActivityIndicator size={50} color={Color.ACCENT} />
                  </BlurryWrapper>
                )}
                {orderStatus === OrderStatus.CANCELLED && (
                  <BlurryWrapper loading={false}>
                    <StatusText active>Cancelled</StatusText>
                  </BlurryWrapper>
                )}

                <Switch
                  id={'status-switch'}
                  isOn={orderStatus === OrderStatus.SENT}
                  handleToggle={toggleOrderStatus}
                  size={SwitchSize.SMALL}
                />

                <Row>
                  <StatusText active={orderStatus === OrderStatus.SENT}>
                    Sent
                  </StatusText>
                  <StatusIcon active={orderStatus === OrderStatus.SENT}>
                    {orderStatus === OrderStatus.SENT && (
                      <icons.FaCheck color={'#06D6A0'} size={16} />
                    )}
                  </StatusIcon>
                </Row>

                <Button
                  label={`${
                    orderStatus === OrderStatus.CANCELLED
                      ? 'Uncancel'
                      : 'Cancel'
                  } order`}
                  size={ButtonSize.SMALL}
                  onClick={toggleCancelOrder}
                  backgroundColor={Color.LIGHT_RED}
                />
              </StatusSwitchWrapper>

              <Divider />

              <ProductList>
                {order.items.map((item, index) => (
                  <CartProductCard key={`${item.id}-${index}`} product={item} />
                ))}
              </ProductList>

              <OrderDetailsTable order={order} />
            </>
          )}
        </OrderContainer>
      </OrderWrapper>
    </Wrapper>
  );
};

const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

const Text = styled.p`
  font-size: 1rem;
  color: ${Color.DARK_GRAY};
`;

const SendEmailModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
`;

const BlurryWrapper = styled.div<{ loading: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  backdrop-filter: blur(5px);
  width: ${({ loading }) => (loading ? '100%' : '200px')};
  height: 130%;
`;

const StatusIcon = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ active }) => !active && `width: 16px`}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StatusText = styled.p<{ active: boolean }>`
  color: ${({ active }) => (active ? Color.DARK_GRAY : Color.GRAY)};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-size: 1rem;
`;

const StatusSwitchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 10px;
  padding: 0 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    justify-self: center;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Color.GRAY};
  margin: 20px 0;
`;

const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 1rem 0;
  margin-bottom: 40px;
  width: 100%;

  @media (min-width: 660px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ActivityIndicatorWrapper = styled.div`
  background-color: ${Color.WHITE};
  border-radius: 10px;
  min-width: 300px;
`;

const OrderContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  border-radius: 10px;
  background-color: ${Color.WHITE};
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    padding: 1.5rem 0;
  }
`;

const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BackButton = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  color: ${Color.DARK_GRAY};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const LogoWrapper = styled.div`
  width: 300px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: ${Color.GRAY};
  min-height: 100svh;
  width: 100svw;

  @media (max-width: 768px) {
    padding: 0;
  }
`;
