import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';

import { Order, OrderStatus } from '@models/order';
import { useMutation } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

// not currently used
export const useUpdateOrder = () => {
  const updateOrder = async () => {
    const ordersRef = collection(db, 'orders');

    const querySnapshot = await getDocs(ordersRef);

    if (querySnapshot.empty) {
      console.log('No orders found.');
      return;
    }

    const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
      const order = docSnapshot.data() as Order;

      const updatedData = {
        ...order,
        status: OrderStatus.PENDING
      };

      const docRef = doc(db, 'orders', docSnapshot.id);
      return updateDoc(docRef, updatedData);
    });

    await Promise.all(updatePromises);

    console.log('All orders updated successfully.');
  };

  return useMutation({
    mutationFn: updateOrder
  });
};
