import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes
} from 'firebase/storage';
import styled from 'styled-components';

import { Color } from '@assets/constants';
import {
  Product,
  ProductType,
  TShirt,
  TShirtSize,
  TShirtSizes,
  TShirtThumbnail,
  TShirtType
} from '@models/product';
import {
  useCreateThumbnail,
  useDeleteProduct,
  useIndexProduct
} from '@mutations';
import { useLabels, useProductById } from '@queries';
import { ActivityIndicator } from '@ui/ActivityIndicator';
import { Button } from '@ui/Button';
import { Checkbox } from '@ui/Checkbox';
import { ColorTile } from '@ui/ColorTile';
import { EdittableAndSelectableItems } from '@ui/EdittableAndSelectableItems';
import { ImageInput } from '@ui/ImageInput';
import { Input } from '@ui/Input';
import Switch, { SwitchSize } from '@ui/Switch';

import { db, storage } from '../../firebase/firebaseConfig';
import {
  ColorImages,
  defaultImageDetails,
  defaultImagesObj,
  defaultSizesObj,
  generateCreatedAt,
  ImageDetails,
  ImageInfo,
  mapSizeToString,
  selectLabelIds,
  SizesCheckbox,
  supportedImageTypes,
  TShirtColor
} from './utils';

interface Props {
  productId: string;
  handleBackToAllProducts: () => void;
}

export const UpdateProductContainer = ({
  productId,
  handleBackToAllProducts
}: Props) => {
  const [productType, setProductType] = useState<ProductType>();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number | string>('');
  const [images, setImages] = useState<ColorImages | ImageDetails>(
    defaultImagesObj
  );
  const [mugImage, setMugImage] = useState<File | ImageInfo>();
  const [thumbnailImage, setThumbnailImage] = useState<string | null>(null);
  const [sizes, setSizes] = useState<SizesCheckbox>(defaultSizesObj.none);
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const [isMock, setIsMock] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState<boolean>(false);

  const { data: product, isPending: isFetchingProduct } =
    useProductById(productId);
  const { data: labels, isPending: isFetchingLabels } = useLabels();
  const { mutate: indexProduct } = useIndexProduct();
  const { mutate: deleteProduct } = useDeleteProduct();
  const { mutateAsync: createThumbnail } = useCreateThumbnail();

  useEffect(() => {
    if (!product) {
      toast.error('💥 Product not found!');
    } else {
      fillForm(product);
    }
  }, [product]);

  const imageForTypeIsSelected = (type: TShirtType) =>
    Object.values(images[type]).some((image) => image);

  const fillForm = (product: Product) => {
    const hasSelectedProduct = !!product.id;

    setProductType(product.type);
    setTitle(hasSelectedProduct ? product.title : '');
    setDescription(product.description);
    setPrice(hasSelectedProduct ? product.price : '');
    setSelectedLabelIds(product.labels);
    setIsMock(product.mock);

    if (product.type === ProductType.TSHIRT) {
      setImages(product.images);
      setThumbnailImage(product.thumbnail.name);
      setSizes(mapSizesToObject(product.sizes));
    } else {
      setMugImage(product.image);
    }
  };

  const mapSizesToObject = (sizes: TShirtSizes): SizesCheckbox => {
    const productSizes: SizesCheckbox = JSON.parse(
      JSON.stringify(defaultSizesObj.none)
    );

    for (const [sizeType, sizesArray] of Object.entries(productSizes)) {
      for (const [size] of Object.entries(sizesArray)) {
        for (const productSize of sizes[sizeType as TShirtType]) {
          if (productSize === size) {
            // @ts-ignore
            productSizes[sizeType as TShirtType][productSize] = true;
          }
        }
      }
    }

    return productSizes;
  };

  const handleSelectMugImage = (files: FileList | null) =>
    setMugImage(files ? files[0] : null);

  const handleSelectImage = (
    files: FileList | null,
    color: string,
    colors: keyof ColorImages
  ) => {
    if (files) {
      setImages({
        ...images,
        [colors]: {
          ...images[colors],
          [color]: files[0]
        }
      });
    }
  };

  const handleSelectSize = (size: TShirtSize, sizeType: TShirtType) => {
    const newSizes = {
      ...sizes,
      [sizeType]: {
        ...sizes[sizeType],
        [size]:
          // @ts-ignore
          !sizes[sizeType][size]
      }
    };
    setSizes(newSizes);
  };

  const updateProduct = async () => {
    const { createdAt, createdAtTimestamp } = generateCreatedAt(Date.now());

    if (productType === ProductType.TSHIRT) {
      let imageDetails: ImageDetails = JSON.parse(
        JSON.stringify(defaultImageDetails)
      );
      const thumbnailImageDetails: TShirtThumbnail = {
        name: '',
        url: '',
        color: TShirtColor.WHITE,
        type: TShirtType.MEN
      };
      for (const [colorType, colors] of Object.entries(images)) {
        for await (const [color, image] of Object.entries(colors)) {
          let imageName = '';
          let imageUrl = '';

          if (image) {
            // @ts-ignore
            if (image.name && image.url) {
              // @ts-ignore
              imageName = image.name;
              // @ts-ignore
              imageUrl = image.url;
            } else {
              const storageRef = ref(
                storage,
                `images/${productId}/${title}-${colorType}-${color}`
              );
              const snapshot = await uploadBytes(storageRef, image as File);
              imageUrl = await getDownloadURL(snapshot.ref);

              imageName = (image as File).name;
            }

            imageDetails = {
              ...imageDetails,
              [colorType as keyof ImageDetails]: {
                ...imageDetails[colorType as keyof ImageDetails],
                [color]: {
                  name: imageName,
                  url: imageUrl
                }
              }
            };
          }

          if (thumbnailImage === imageName) {
            const { thumbnailUrl } = await createThumbnail({
              imageUrl,
              productId
            });

            thumbnailImageDetails.name = imageName;
            thumbnailImageDetails.url = thumbnailUrl;
            thumbnailImageDetails.color = color as TShirtColor;
            thumbnailImageDetails.type = colorType as TShirtType;
          }
        }
      }

      const sizesObj: TShirtSizes = {
        men: [],
        women: [],
        kids: [],
        oversized: []
      };
      for (const [sizeType, sizesArray] of Object.entries(sizes)) {
        const imageSelected = imageForTypeIsSelected(sizeType as TShirtType);
        if (!imageSelected) {
          continue;
        }

        for (const [size, selected] of Object.entries(sizesArray)) {
          if (selected) {
            sizesObj[sizeType as TShirtType].push(size as TShirtSize);
          }
        }
      }

      const updatedProduct = {
        createdAt,
        createdAtTimestamp,
        title,
        description,
        price: Number(price),
        thumbnail: thumbnailImageDetails,
        images: imageDetails,
        sizes: sizesObj,
        labels: selectedLabelIds,
        type: ProductType.TSHIRT,
        mock: isMock
      };

      await updateDoc(doc(db, 'products', productId), updatedProduct);

      return { ...updatedProduct, id: productId };
    }

    let imageName = '';
    let imageUrl = '';

    if (mugImage) {
      // @ts-ignore
      if (mugImage.name && mugImage.url) {
        imageName = mugImage.name;
        // @ts-ignore
        imageUrl = mugImage.url;
      } else {
        const storageRef = ref(storage, `images/${productId}/${title}`);
        const snapshot = await uploadBytes(storageRef, mugImage as File);
        imageUrl = await getDownloadURL(snapshot.ref);
        imageName = mugImage.name;
      }
    }

    const { thumbnailUrl } = await createThumbnail({
      imageUrl,
      productId
    });

    const updatedProduct = {
      createdAt,
      createdAtTimestamp,
      title,
      description,
      price: Number(price),
      image: {
        name: imageName,
        url: imageUrl
      },
      thumbnail: {
        name: imageName,
        url: thumbnailUrl
      },
      labels: selectedLabelIds,
      type: ProductType.MUG,
      mock: isMock
    };

    await updateDoc(doc(db, 'products', productId), updatedProduct);

    return { ...updatedProduct, id: productId };
  };

  const updateExistingProduct = async () => {
    if (!title) {
      return toast.error('💥 Please add a title for your product!');
    }
    if (!description) {
      return toast.error('💥 Please add a description for your product.');
    }
    if (!price) {
      return toast.error('💥 Please add a price for your product.');
    }
    const hasSize = Object.values(sizes).filter((selected) => selected).length;
    if (!hasSize) {
      return toast.error(
        '💥 Please choose at least one size for your product.'
      );
    }

    setIsLoading(true);
    try {
      const product = await updateProduct();

      if (product) {
        // TODO: Fix later for mugs
        indexProduct(product as unknown as TShirt);
      }
      handleBackToAllProducts();

      return toast.success('Product updated successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return toast.error(`💥 ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProduct = async (productId: string) => {
    setIsDeletingProduct(true);

    try {
      await deleteDoc(doc(db, 'products', productId));
      await deleteProduct(productId);

      const imagesRef = ref(storage, `images/${productId}`);
      const images = await listAll(imagesRef);
      images.items.forEach(async (image) => {
        await deleteObject(image);
      });

      toast.success('Product deleted successfully!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setIsDeletingProduct(false);
      return toast.error(`💥 ${e.message}`);
    } finally {
      setIsDeletingProduct(false);
      handleBackToAllProducts();
    }
  };

  return (
    <Wrapper>
      {isFetchingProduct ? (
        <ActivityIndicatorWrapper>
          <ActivityIndicator size={100} color={Color.ACCENT} />
        </ActivityIndicatorWrapper>
      ) : (
        <>
          <InputContainer>
            <Text>Title</Text>
            <Input
              value={title}
              placeholder={'Title...'}
              onChange={(e) => setTitle(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <Text>Description</Text>
            <Input
              value={description}
              placeholder={'Description...'}
              onChange={(e) => setDescription(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <Text>Price</Text>
            <Input
              value={price}
              placeholder={'Price...'}
              type={'number'}
              onChange={(e) => setPrice(e.target.value)}
            />
          </InputContainer>

          {productType === ProductType.TSHIRT && (
            <>
              <Text>Thumbnail Image</Text>
              <SizesWrapper>
                <ImageInputContainer>
                  <SmallText>Thumbnail:</SmallText>
                  <ThumbnailImage thumbnail={thumbnailImage}>
                    {thumbnailImage ?? 'N/A'}
                  </ThumbnailImage>
                </ImageInputContainer>
              </SizesWrapper>
              <Text>Images</Text>
              <SizesWrapper>
                <ImageInputWrapper>
                  {Object.keys(images).map((colors, index) => (
                    <ColorTypeContainer key={`color-type-${index}`}>
                      <SmallText key={`color-${index}`}>
                        {colors.toUpperCase()}:
                      </SmallText>
                      {Object.keys(images[colors as keyof ColorImages]).map(
                        (color, index) => (
                          <ImageInputContainer key={`image-input-${index}`}>
                            <ColorTile color={color} />
                            <ImageInput
                              fileName={
                                // @ts-ignore
                                images[colors as keyof ColorImages][color]?.name
                              }
                              supportedTypes={supportedImageTypes}
                              onChange={(e) =>
                                handleSelectImage(
                                  e.target.files,
                                  color,
                                  colors as keyof ColorImages
                                )
                              }
                              onMakeThumbnail={(fileName) =>
                                setThumbnailImage(fileName)
                              }
                              thumbnailSelected={
                                thumbnailImage ===
                                // @ts-ignore
                                images[colors as keyof ColorImages][color]?.name
                              }
                            />
                          </ImageInputContainer>
                        )
                      )}
                    </ColorTypeContainer>
                  ))}
                </ImageInputWrapper>
              </SizesWrapper>

              <Text>T-Shirt Sizes</Text>
              <SizesWrapper>
                {Object.keys(sizes).map((sizeType, index) => {
                  const showSizesForType = imageForTypeIsSelected(
                    sizeType as TShirtType
                  );

                  return (
                    <SizesRow
                      disabled={!showSizesForType}
                      key={`size-row-${index}`}
                    >
                      <SmallText key={`size-${index}`}>
                        {sizeType.toUpperCase()}:
                      </SmallText>
                      <InputContainer key={`size-input-${index}`}>
                        <SizesContainer>
                          {Object.keys(sizes[sizeType as TShirtType]).map(
                            (size, index) => {
                              const checked =
                                // @ts-ignore
                                sizes[sizeType][size];

                              return (
                                <CheckboxContainer key={`checkbox-${index}`}>
                                  <Checkbox
                                    label={mapSizeToString(size as TShirtSize)}
                                    checked={checked}
                                    onClick={() =>
                                      handleSelectSize(
                                        size as TShirtSize,
                                        sizeType as TShirtType
                                      )
                                    }
                                  />
                                </CheckboxContainer>
                              );
                            }
                          )}
                        </SizesContainer>
                      </InputContainer>
                    </SizesRow>
                  );
                })}
              </SizesWrapper>
            </>
          )}

          {productType === ProductType.MUG && (
            <>
              <Text>Image</Text>
              <MugImageInputWrapper>
                <ImageInput
                  fileName={mugImage?.name}
                  supportedTypes={supportedImageTypes}
                  onChange={(e) => handleSelectMugImage(e.target.files)}
                />
              </MugImageInputWrapper>
            </>
          )}

          <InputContainer>
            <Text>Labels</Text>
            <EdittableAndSelectableItems
              items={labels}
              selectedItemIds={selectedLabelIds}
              handleSelectItem={(labelId) =>
                selectLabelIds(labelId, selectedLabelIds, setSelectedLabelIds)
              }
              isFetchingItems={isFetchingLabels}
              selective
            />
          </InputContainer>

          <MockSwitchWrapper>
            <Text>Mock Product</Text>
            <Switch
              isOn={isMock}
              handleToggle={() => setIsMock((prev) => !prev)}
              id={'mockSwitch'}
              size={SwitchSize.SMALL}
            />
          </MockSwitchWrapper>

          <ButtonContainer>
            <Button
              label={'Update product'}
              loading={isLoading}
              onClick={updateExistingProduct}
            />
            <Button
              label={'Delete product'}
              backgroundColor={Color.RED}
              loading={isDeletingProduct}
              onClick={() => handleDeleteProduct(productId)}
            />
          </ButtonContainer>
        </>
      )}
    </Wrapper>
  );
};

const MockSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ActivityIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;

const ThumbnailImage = styled.div<{ thumbnail: string | null }>`
  border: none;
  padding: 8px 16px;
  background-color: ${(props) =>
    props.thumbnail ? Color.ACCENT : Color.LIGHT_GRAY};
  border-radius: 2rem;
  color: ${(props) => (props.thumbnail ? Color.BLACK : Color.GRAY)};
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

const SizesRow = styled.div<{ disabled: boolean }>`
  ${(props) =>
    props.disabled &&
    `
      pointer-events: none;
      opacity: 0.25;
      filter: blur(2px);
  `}
`;

const ColorTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${Color.GRAY};
  padding-bottom: 10px;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const SizesWrapper = styled.div`
  margin: 10px 0 10px 0;
  padding: 10px;
  background-color: ${Color.DARK_GRAY};
  border-radius: 10px;
  overflow-y: scroll;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const SizesContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const SmallText = styled.p`
  color: ${Color.WHITE};
`;

const ImageInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MugImageInputWrapper = styled.div`
  margin-top: 10px;
`;

const ImageInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  color: ${Color.WHITE};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0 10px 0;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;
