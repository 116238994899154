import { toast } from 'react-toastify';

import { collection, getDocs } from 'firebase/firestore';

import { Banner } from '@models/banner';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebase/firebaseConfig';

export const useBanners = () => {
  const getBanners = async () => {
    const bannersCollectionRef = collection(db, 'banners');

    try {
      const data = await getDocs(bannersCollectionRef);
      const banners = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      })) as Banner[];

      banners.sort((a, b) => a.index - b.index); // sort by index
      return banners;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(`💥 ${e.message}`);
      return [];
    }
  };

  return useQuery({
    queryKey: ['banners'],
    queryFn: getBanners,
    initialData: []
  });
};
