import { TShirtColor } from '@containers/adminPanel/utils';
import {
  Mug,
  ProductType,
  TShirt,
  TShirtSize,
  TShirtType
} from '@models/product';

export interface CartTShirtProduct {
  id: string;
  title: string;
  description: string;
  image: string;
  price: number;
  color: TShirtColor;
  size: TShirtSize;
  type: TShirtType;
  customizationNotes?: string;
  productType: ProductType.TSHIRT;
}

export interface CartMugProduct {
  id: string;
  title: string;
  description: string;
  image: string;
  price: number;
  customizationNotes?: string;
  productType: ProductType.MUG;
}

export type CartProduct = CartTShirtProduct | CartMugProduct;
export interface MyPosProduct {
  article: string;
  quantity: number;
  price: number;
  currency: string;
}

export const mapTShirtToCartProduct = (
  product: TShirt,
  color: TShirtColor,
  image: string,
  selectedSize: TShirtSize,
  selectedType: TShirtType,
  customizationNotes?: string,
  discountedPrice?: number
): CartTShirtProduct => ({
  id: product.id,
  title: product.title,
  description: product.description,
  image,
  price: Number(discountedPrice ?? product.price),
  color,
  size: selectedSize,
  type: selectedType,
  customizationNotes,
  productType: ProductType.TSHIRT
});

export const mapMugToCartProduct = (
  product: Mug,
  image: string,
  customizationNotes?: string,
  discountedPrice?: number
): CartMugProduct => ({
  id: product.id,
  title: product.title,
  description: product.description,
  image,
  price: Number(discountedPrice ?? product.price),
  customizationNotes,
  productType: ProductType.MUG
});

export const mapCartItemsToMyPosProduct = (
  cartItems: CartProduct[]
): MyPosProduct[] =>
  cartItems.map(
    (item: CartProduct): MyPosProduct => ({
      article:
        item.productType === ProductType.TSHIRT
          ? 'Тениска с щампа'
          : 'Чаша със снимка или надпис',
      quantity: 1,
      price: item.price,
      currency: 'BGN'
    })
  );
