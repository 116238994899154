import React from 'react';

import styled from 'styled-components';

import { Color } from '@assets/constants';

interface Props {
  color: Color;
  size: number;
}

export const ActivityIndicator = ({ color, size }: Props) => {
  return (
    <Wrapper>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
      >
        <circle className="spinner_b2T7" cx="4" cy="12" r="3" />
        <circle className="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3" />
        <circle className="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3" />
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
