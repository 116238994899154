import { doc, getDoc } from 'firebase/firestore';

import { Product } from '@models/product';
import { LocalItem } from '@store';
import { useQueries } from '@tanstack/react-query';

import { CartProduct } from '../domain/mappers/cartProductMapper';
import { db } from '../firebase/firebaseConfig';

export const useProductsById = (cartItems: LocalItem[] | CartProduct[]) => {
  const getProductById = async (
    productId: string
  ): Promise<Product | undefined> => {
    try {
      const docRef = doc(db, 'products', productId);
      const productDoc = await getDoc(docRef);

      if (productDoc.exists()) {
        const product = { ...productDoc.data(), id: productDoc.id } as Product;

        return product;
      }
    } catch (error) {
      throw new Error('Error getting product: ', error as unknown as Error);
    }
  };

  return useQueries({
    queries: cartItems.map((item) => {
      return {
        queryKey: ['product', item.id],
        queryFn: () => getProductById(item.id)
      };
    }),
    combine: (results) => {
      return {
        products: results.map((result) => result.data),
        isFetchingProducts: results.every((result) => result.isPending)
      };
    }
  });
};
